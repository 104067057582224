import React, { useState } from 'react';
import LoginComponent from './LoginPage.component';
import SignUpComponent from './SignupPage.component';

// Switches the component to login or signup
const AuthComponent = () => {
  const [page, setPage] = useState(true);
  return (
    <main>
      {page ? (
        <LoginComponent setPage={setPage} />
      ) : (
        <SignUpComponent setPage={setPage} />
      )}
    </main>
  );
};

export default AuthComponent;
