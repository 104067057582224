import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReviewQuizContainer } from '../Completed/QuizComplete.styles';
import DisplayAnswersComponent from '../DisplayAnswers/DisplayAnsComponent.component';
import TertiaryButton from '../../Components/Buttons/TertiaryButton/TertiaryButton.component';
import Auth from '../../Helpers/auth';
import { httpGetUser } from '../../Hooks/users.hooks';
import InfoCard from '../../Components/InfoCard/InfoCard.component';

const ReviewQuiz = ({ dataCourse }) => {
  const [showAnswers, setShowAnswers] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  const { data } = Auth.getProfile();
  useEffect(() => {
    const GetAnswers = async () => {
      const info = await httpGetUser(data?.id);
      const answers = await info?.courses.find(
        (course) => course?.courseId === dataCourse?._id
      );
      setUserAnswers(answers?.userAnswers);
    };
    GetAnswers();
  }, []);

  return (
    <>
      <ReviewQuizContainer>
        <div className="topDiv">
          <h2>Review Quiz</h2>
          <TertiaryButton
            span={showAnswers ? 'Hide Answers' : 'Show Answers'}
            functionName={() => {
              setShowAnswers(!showAnswers);
            }}
          />
        </div>
        <InfoCard
          paragraph="Answers are highlighted in green. If you got a question wrong, your
            answer will be highlighted in red. To see the answers select the
            button to the right to show the answers."
          span="Note: You are able to do the quiz as many times as needed"
        />
        {showAnswers &&
          dataCourse &&
          dataCourse.quiz &&
          dataCourse.quiz.map((question, idx) => {
            return (
              <DisplayAnswersComponent
                key={question._id}
                question={question}
                idx={idx}
                userAnswers={userAnswers}
              />
            );
          })}
      </ReviewQuizContainer>
    </>
  );
};
ReviewQuiz.propTypes = {
  dataCourse: PropTypes.shape({
    quiz: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
      })
    ),
    _id: PropTypes.string.isRequired,
  }).isRequired,
};

export default ReviewQuiz;
