import styled from 'styled-components';

export const EachCourse = styled.div`
  margin-top: ${({ theme }) => theme.spacing.l};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: ${({ theme }) => theme.spacing.m};
  background-color: ${({ theme }) => theme.colors.offWhite};
  .top {
    display: flex;
    flex-direction: column;
  }
  .title {
    font-weight: 700;
    width: 48%;
    font-family: 'Oswald', sans-serif;
    color: ${({ theme }) => theme.colors.grey5};
    letter-spacing: ${({ theme }) => theme.letterSpacing.courseTitles};
    padding-bottom: ${({ theme }) => theme.spacing.xs};
    font-size: ${({ theme }) => theme.spacing.l};
    position: relative;
    &:hover {
      color: ${({ theme }) => theme.colors.orange5};
    }
    /* &:hover::after {
      content: '> Review Course';
      color: ${({ theme }) => theme.colors.orange5};
      position: absolute;
      right: 0;
      top: 0;
      color: ${({ theme }) => theme.colors.grey4};
    } */
  }
  button {
    width: auto;
    max-width: 100%;
    //margin: 0 0.5rem 0.5rem 00;
  }
  p {
    font-weight: 600;
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
    color: ${({ theme }) => theme.colors.grey5};
  }
  p span {
    font-style: italic;
    color: ${({ theme }) => theme.colors.grey3};
  }
  @media screen and (width <= 600px) {
    .title {
      font-size: ${({ theme }) => theme.spacing.l};
    }
  }
`;

export const BottomCourseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (width <= 600px) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.m};
  }
`;

export const CourseStatusContainer = styled.div`
  /* border: 1px solid orange; */
  height: 100vh;
  text-align: center;
  text-transform: capitalize;
  button {
    margin-top: 2rem;
  }
`;
