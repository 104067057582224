import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButtonContainer } from './PrimaryButton.styles';

// Primary Button Component
const PrimaryButton = ({
  functionName,
  span,
  disabled = false,
  title = '',
}) => {
  return (
    <PrimaryButtonContainer
      onClick={functionName}
      className="primaryButton boxShadow"
      disabled={disabled}
      title={title}
    >
      <span>{span}</span>
    </PrimaryButtonContainer>
  );
};
PrimaryButton.propTypes = {
  functionName: PropTypes.func.isRequired,
  span: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

export default React.memo(PrimaryButton);
