import { EachInput } from '../../Authentication/AuthStyles.styles';
import PropTypes from 'prop-types';
import React from 'react';
// Password input for login
// prettier-ignore
const PasswordInput = ({
  label,
  span,
  handleChange,
  showPass,
  value,
  name,
  onChange,
}) => {
  
  return (
    <EachInput>
      <div className="passwordContainer">
        <div>
        <label>{label}</label>
        {span && <span className="italicSpan">
          * {span}
        </span>}
        </div>
        <span className="passwordSwitch" onClick={handleChange}>
          {!showPass ? 'Show password' : 'Hide password'}
        </span>
      </div>
      <input
        type={!showPass ? 'password' : 'text'}
        value={value}
        name={name}
        onChange={onChange}
        required
      />
    </EachInput>
  );
};
PasswordInput.propTypes = {
  label: PropTypes.string.isRequired,
  span: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  showPass: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PasswordInput;
