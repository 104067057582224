import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import Loader from '../../../Components/Loader/LoaderComponent.component';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createCourse } from '../../../Utils/Courses/createCourse/postCourse';

// Submit Function for Handling the course Submit
const CreateCourseSubmit = ({
  questions = [],
  pdf = '',
  notes = '',
  videoLinks = [],
  documentation = [],
  image = '',
  expiryDate = 0,
  certificatePrefix = '',
  courseName = '',
  tags = [],
  description = '',
  price = 0,
  setSuccessState,
  setIsOpen,
  disabled,
  successState,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const fullCourse = {
      courseName: courseName,
      price: price,
      description: description,
      tags: tags,
      notes: notes,
      videoLinks: videoLinks,
      documentation: documentation,
      quiz: questions,
      expiryDate: expiryDate,
      certificatePrefix: certificatePrefix,
    };
    const formData = new FormData();
    formData.append('pdf', pdf);
    formData.append('image', image);
    formData.append('courseName', courseName);

    await createCourse(
      fullCourse,
      formData,
      setSuccessState,
      setIsOpen,
      setLoading
    );
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p>{successState}</p>
          <PrimaryButton
            functionName={(e) => handleSubmit(e)}
            span="Create Course"
            disabled={!disabled}
          />
        </div>
      )}
    </>
  );
};

CreateCourseSubmit.propTypes = {
  questions: PropTypes.array,
  pdf: PropTypes.object,
  notes: PropTypes.string,
  videoLinks: PropTypes.array,
  documentation: PropTypes.array,
  image: PropTypes.object,
  expiryDate: PropTypes.number,
  certificatePrefix: PropTypes.string,
  courseName: PropTypes.string,
  tags: PropTypes.array,
  description: PropTypes.string,
  price: PropTypes.number,
  setSuccessState: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  successState: PropTypes.string,
};

export default CreateCourseSubmit;
