import React from 'react';
import PropTypes from 'prop-types';
import { UserInfo, UserLinks } from './UserHomeStyles.styles';
import { Link } from 'react-router-dom';
import icon from '../../Visuals/Images/Default.jpg';
import SettingsIcon from '../../Visuals/Icons/SettingsIcon.icon';
import UserIcon from '../../Visuals/Icons/UserIcon.icon';
import CreditCardIcon from '../../Visuals/Icons/CreditCardIcon.icon';
import LogoutIcon from '../../Visuals/Icons/LogoutIcon.icon';
import Auth from '../../Helpers/auth';

const UserMenu = ({ data }) => {
  const url = `/user/settings/${data.id}`;
  const linkStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  };

  return (
    <>
      <UserInfo>
        <img src={icon} alt="user" />
        <h2>
          {data?.firstName} {data?.lastName}
        </h2>
      </UserInfo>
      <UserLinks>
        {data.type === 'Admin' && (
          <Link className="subLink" to="/admin" style={linkStyles}>
            <UserIcon />
            Admin Homepage
          </Link>
        )}
        <Link className="subLink" to={`/online`} style={linkStyles}>
          <CreditCardIcon />
          Buy Courses
        </Link>
        <Link className="subLink" to={url} style={linkStyles}>
          <SettingsIcon />
          Settings
        </Link>
        <Link
          onClick={(e) => {
            e.preventDefault();
            Auth.logout();
            window.location.assign('/');
          }}
          title="Logout"
          className="subLink"
          to="/about"
          style={linkStyles}
        >
          <LogoutIcon />
          Logout
        </Link>
      </UserLinks>
    </>
  );
};
UserMenu.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default UserMenu;
