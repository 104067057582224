import {
  CourseContainer,
  TagContainer,
  OnlineCoursesContainer,
  SearchContainer,
  SearchBarContainer,
} from './TrainingStyles.styles';
import RadioTag from './RadioTag';
import Modal from '../../Components/Modal/ModalComponent.component';
import CourseCardSingle from './CourseCardComponent.component';
import { httpGetAllCourses } from '../../Hooks/courses.hooks';
import React, { useState, useEffect } from 'react';
import { httpFilterBySearch, httpFilterByTag } from '../../Hooks/courses.hooks';
import PrimaryButton from '../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';

const OnlineTraining = () => {
  const [allCourses, setAllCourses] = useState();
  const [selectedTag, setSelectedTag] = useState('all');
  const [isOpen, setIsOpen] = useState(false);
  const [searching, setSearching] = useState('');
  // const [error, setError] = useState('');
  const { data, error, isLoading } = useQuery({
    queryKey: ['allCourses'],
    queryFn: () => httpGetAllCourses(),
    retry: 5,
  });
  useEffect(() => {
    if (data) {
      setAllCourses(data);
    }
  }, [data]);
  // Button function for searching
  const searchQuery = async (e) => {
    e.preventDefault();
    if (searching.length === 0) {
      setAllCourses(data);
      return;
    }
    let searchedForResults = await httpFilterBySearch(searching);
    if (searchedForResults) {
      setAllCourses(searchedForResults);
      return;
    }
  };

  const tagChange = async (selectedTag) => {
    if (selectedTag === 'all') {
      setAllCourses(allCourses);
      return;
    } else {
      let searchedForResults = await httpFilterByTag(selectedTag);
      if (searchedForResults) {
        setAllCourses(searchedForResults);
      }
    }
  };

  return (
    <OnlineCoursesContainer>
      <Helmet>
        <title>Online Training | Yoda Safety Services</title>
        <meta
          name="Online Training"
          content="Training online from yoda safety services"
        />
      </Helmet>
      <SearchContainer>
        <h2>Online Courses</h2>
        <SearchBarContainer>
          <input
            type="text"
            onChange={(e) => setSearching(e.target.value)}
            value={searching}
            placeholder="Search for a course"
            required
            name="searching"
          />
          <PrimaryButton functionName={searchQuery} span="search" />
        </SearchBarContainer>
      </SearchContainer>
      <section style={{ display: 'flex' }}>
        <TagContainer>
          <RadioTag
            tagChange={tagChange}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
          />
        </TagContainer>
        <CourseContainer>
          {isLoading && <h3>Loading...</h3>}
          {error && <h3>There was an error getting the courses</h3>}
          {allCourses?.map((c) => {
            return <CourseCardSingle key={c._id} c={c} />;
          })}
        </CourseContainer>
        {isOpen && (
          <Modal onClose={() => setIsOpen(false)}>
            <RadioTag />
          </Modal>
        )}
      </section>
    </OnlineCoursesContainer>
  );
};

export default OnlineTraining;
