import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { QuizContainer, ButtonContainer } from './Quiz.styles';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import SecondaryButton from '../../../Components/Buttons/SecondaryButton/SecondaryButton.component';
import PropTypes from 'prop-types';
import InputTextNested from '../InputTextNested';

// Component for creating a question
const CreateQuestion = ({ questions, setQuestions }) => {
  const [option, setOption] = useState(true);
  //const [error, setError] = useState('');
  // 4 options question
  const [quest, setQuest] = useState({
    question: '',
    answer1: '',
    answer2: '',
    answer3: '',
    answer4: '',
    correctAnswer: '',
    id: uuidv4(),
  });
  // True or false question
  const [quest2, setQuest2] = useState({
    question: '',
    answer1: 'true',
    answer2: 'false',
    correctAnswer: '',
    id: uuidv4(),
  });
  // Clears the inputs on the form after a question is submitted on both options for the forms
  const clearInput = () => {
    setQuest({
      id: uuidv4(),
      question: '',
      answer1: '',
      answer2: '',
      answer3: '',
      answer4: '',
      correctAnswer: quest.answer1,
    });
    setQuest2({
      question: '',
      answer1: 'true',
      answer2: 'false',
      correctAnswer: 'true',
      id: uuidv4(),
    });
  };
  // Handles the submit of a question for both kinds of questions
  const handleSubmit = (e) => {
    e.preventDefault();
    if (option) {
      setQuestions([...questions, quest]);
      setQuest({
        id: uuidv4(),
        question: '',
        answer1: '',
        answer2: '',
        answer3: '',
        answer4: '',
        correctAnswer: '',
      });
    } else {
      const newQuestion = {
        id: uuidv4(),
        question: quest2.question,
        answer1: 'true',
        answer2: 'false',
        correctAnswer: quest2.correctAnswer,
      };
      setQuestions([...questions, newQuestion]);
      setQuest2({
        question: '',
        answer1: 'true',
        answer2: 'false',
        correctAnswer: 'true',
        id: uuidv4(),
      });
    }
  };

  return (
    <QuizContainer>
      {option ? (
        <>
          <InputTextNested
            className="item"
            title="Question"
            value={quest?.question}
            name="question"
            nestedValue={setQuest}
            upperValue={quest}
          />
          {/* {error && <p className="error">{error}</p>} */}
          <InputTextNested
            className="list"
            title="Answer 1"
            value={quest?.answer1}
            name="answer1"
            nestedValue={setQuest}
            upperValue={quest}
          />
          <InputTextNested
            className="list"
            title="Answer 2"
            value={quest?.answer2}
            name="answer2"
            nestedValue={setQuest}
            upperValue={quest}
          />
          <InputTextNested
            className="list"
            title="Answer 3"
            value={quest?.answer3}
            name="answer3"
            nestedValue={setQuest}
            upperValue={quest}
          />
          <InputTextNested
            className="list"
            title="Answer 4"
            value={quest?.answer4}
            name="answer4"
            nestedValue={setQuest}
            upperValue={quest}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <h5>
              Correct Answer:{' '}
              {quest?.correctAnswer.length > 0
                ? quest?.correctAnswer
                : 'No answer selected'}
            </h5>
            <select
              multiple={false}
              // defaultValue={quest?.answer1}
              value={quest?.correctAnswer || ''}
              name="correctAnswer"
              onChange={(e) =>
                setQuest({ ...quest, [e.target.name]: e.target.value })
              }
            >
              <option value={quest?.answer1}>Answer 1</option>
              <option value={quest?.answer2}>Answer 2</option>
              <option value={quest?.answer3}>Answer 3</option>
              <option value={quest?.answer4}>Answer 4</option>
            </select>
          </div>
        </>
      ) : (
        <>
          <InputTextNested
            className="item"
            title="Question"
            value={quest2.question}
            name="question"
            nestedValue={setQuest2}
            upperValue={quest2}
          />
          {/* {error && <p className="error">{error}</p>} */}
          <p className="list">1. True</p>
          <p className="list">2. False</p>
          <div style={{ display: 'flex', gap: '3rem' }}>
            <p style={{ fontSize: '1.5rem' }}>
              Correct Answer: {quest2.correctAnswer}
            </p>
            <select
              multiple={false}
              // defaultValue="true"
              value={quest2.correctAnswer || ''}
              name="correctAnswer"
              onChange={(e) =>
                setQuest2({ ...quest2, [e.target.name]: e.target.value })
              }
            >
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
        </>
      )}
      <ButtonContainer>
        <SecondaryButton
          functionName={(e) => {
            e.preventDefault();
            setOption(!option);
            clearInput();
          }}
          span="Select Type"
        />
        <PrimaryButton
          functionName={(e) => handleSubmit(e)}
          span="Add Question"
        />
      </ButtonContainer>
    </QuizContainer>
  );
};
CreateQuestion.propTypes = {
  questions: PropTypes.array,
  setQuestions: PropTypes.func.isRequired,
};

export default CreateQuestion;
