import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import NotFoundImg from '../../../Visuals/Images/NotFound.svg';
import { NotFoundContainer } from './NotFound.styles';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Helmet } from 'react-helmet';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <NotFoundContainer>
      <Helmet>
        <title>Not Found | Yoda Safety Services</title>
        <meta name="Not Found" content="Content has not been found" />
      </Helmet>
      <img src={NotFoundImg} alt="404 illustration" />
      <div>
        <h2>Sorry, this page is gone</h2>
        <PrimaryButton functionName={() => navigate('/')} span="Return Home" />
      </div>
    </NotFoundContainer>
  );
};

export default NotFound;
