import { httpGetCompanyManagers } from '../../../../Hooks/companies.hooks';

export const fetchManagers = async (id, setCompany, setSuccessState) => {
  try {
    const found = await httpGetCompanyManagers(id);
    if (found) {
      setCompany(found);
    } else {
      setSuccessState('No managers found');
    }
  } catch (error) {
    setSuccessState('An error occurred while fetching the managers');
    console.error('Error fetching managers:', error);
  }
};
