import React, { useState } from 'react';
import CreateUserSubmit from './UserCreateSubmit';
import Modal from '../../../Components/Modal/ModalComponent.component';
import { useNavigate } from 'react-router-dom';
import { FormContainer, SubmitContainer } from '../CreateForm.styles';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { AuthCheckAdmin } from '../../../Utils/AuthCheck/AdminCheck.auth';
import InputTextNested from '../../../Components/Form/InputTextNested';
import { Helmet } from 'react-helmet';

// Creating a user form
const CreateUser = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });
  const [isOpen, setIsOpen] = useState(false);
  const [successState, setSuccessState] = useState('');
  const validForm = Object.values(user).every((value) => value.trim() !== '');

  return (
    <AuthCheckAdmin>
      <Helmet>
        <title>Create User | Yoda Safety Services</title>
        <meta name="Create User" content="Create a user" />
      </Helmet>
      <FormContainer>
        <h2>CREATE USER</h2>
        <form>
          <InputTextNested
            title="First Name"
            value={user.firstName}
            name="firstName"
            requiredInput={true}
            nestedValue={setUser}
            upperValue={user}
            containerClassName="formInputUnderline"
          />
          <InputTextNested
            title="Last Name"
            value={user.lastName}
            name="lastName"
            requiredInput={true}
            nestedValue={setUser}
            upperValue={user}
            containerClassName="formInputUnderline"
          />
          <InputTextNested
            title="Email"
            value={user.email}
            name="email"
            requiredInput={true}
            nestedValue={setUser}
            upperValue={user}
            containerClassName="formInputUnderline"
          />
          <InputTextNested
            title="Password"
            value={user.password}
            name="password"
            requiredInput={true}
            nestedValue={setUser}
            upperValue={user}
            containerClassName="formInputUnderline"
          />
          {isOpen && (
            <Modal onClose={() => setIsOpen(false)}>
              <p>{successState}</p>
              <PrimaryButton
                functionName={() => navigate('/admin')}
                span="Go Home"
              />
            </Modal>
          )}
          <SubmitContainer>
            <CreateUserSubmit
              user={user}
              setIsOpen={setIsOpen}
              setSuccessState={setSuccessState}
              disabled={validForm}
              successState={successState}
            />
          </SubmitContainer>
        </form>
      </FormContainer>
    </AuthCheckAdmin>
  );
};

export default CreateUser;
