import { httpDeleteCourse } from '../../../Hooks/admin.hooks';

export const deleteCourse = async (
  id,
  setIsOpen,
  setLoading,
  setSuccessState
) => {
  try {
    let res = await httpDeleteCourse(id);
    let data = await res.json();
    if (res.ok) {
      setSuccessState('Course has been deleted');
    } else {
      setSuccessState(data.msg);
    }
  } catch (err) {
    setSuccessState('An error has occured deleting the course');
  } finally {
    setIsOpen(true);
    setLoading(false);
  }
};
