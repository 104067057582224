import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import Loader from '../../../Components/Loader/LoaderComponent.component';
import { AuthCheckAdmin } from '../../../Utils/AuthCheck/AdminCheck.auth';
import useCourses from '../../../Utils/Courses/allCourses/useCourses';
import { freeCourse } from '../../../Utils/Courses/buyCourse/freeCourse/purchaseFreeCourse';

// Buy a course for the user - no payment
const FreeCourse = ({ id = '' }) => {
  const [selectedCourse, setSelectedCourse] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);

  //successState, setCourses, setSuccessState
  const { courses } = useCourses();

  const purchaseCourse = async () => {
    setLoading(true);
    if (!id) {
      setStatus('User ID is missing');
      setLoading(false);
      return;
    }
    if (status.length) {
      setStatus('');
    }
    const course = { courseId: selectedCourse, userId: id };
    await freeCourse(course, setStatus, setLoading);
  };

  return (
    <AuthCheckAdmin>
      {/* <FreeCourseComponent courses={courses} selectedCourse={selectedCourse} setSelectedCourse={setSelectedCourse} loading={loading} purchaseCourse={purchaseCourse} status={status} /> */}
      <div
        className="boxShadow"
        style={{
          backgroundColor: 'var(--grey)',
          marginTop: '1rem',
          padding: '1rem',
          borderRadius: '5px',
        }}
      >
        <h4>Assign a course</h4>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '2rem',
            marginTop: '1rem',
          }}
        >
          <label htmlFor="courses" style={{ marginBottom: '2rem' }}>
            Courses
          </label>
          <select
            id="courses"
            value={selectedCourse}
            onChange={(e) => setSelectedCourse(e.target.value)}
          >
            <option value="">Select Course</option>
            {courses?.map((course) => {
              return (
                <option key={course?._id} value={course?._id}>
                  {course?.courseName}
                </option>
              );
            })}
          </select>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <PrimaryButton functionName={purchaseCourse} span="Buy Course" />
        )}
        {status && <p>{status}</p>}
      </div>
    </AuthCheckAdmin>
  );
};
FreeCourse.propTypes = {
  id: PropTypes.string,
};

export default FreeCourse;
