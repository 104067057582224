import Auth from '../Helpers/auth';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = Auth.getToken();
//localStorage.getItem('yoda_token');

// GET: Gets the users profile - protected route
async function httpGetUser(userId) {
  try {
    let user = await fetch(`${API_URL}/users/${userId}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return user.json();
  } catch (err) {
    return err;
  }
}

// GET: Gets the users certificate information - protected route
async function httpGetCertificateInfo(id, course) {
  try {
    let user = await fetch(`${API_URL}/users/cert/${id}/${course}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return user.json();
  } catch (err) {
    return err;
  }
}

// POST: Login the user - Not a protected route
async function httpLoginUser(userInfo) {
  try {
    return await fetch(`${API_URL}/users/login`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userInfo),
    });
  } catch (err) {
    return err;
  }
}

//POST: Sign Up User - Not a protected route
async function httpSignUpUser(userInfo) {
  try {
    return await fetch(`${API_URL}/users/signup`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userInfo),
    });
  } catch (err) {
    return err;
  }
}

//POST: Forgot Password - Not a protected route
async function httpForgotPassword(email) {
  try {
    return await fetch(`${API_URL}/users/forgot-password`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
  } catch (err) {
    return err;
  }
}

//POST: Reset Password - Not a protected route
async function httpResetPassword(token, password) {
  try {
    return await fetch(`${API_URL}/users/reset-password`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, password }),
    });
  } catch (err) {
    return err;
  }
}

// PUT: Set complete course for user - Protected route
async function httpCompleteCourse(course, id) {
  try {
    return await fetch(`${API_URL}/users/complete`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify({ course, id }),
    });
  } catch (err) {
    return err;
  }
}

// PUT: Set incomplete course for user - Protected route
async function httpIncompleteCourse(course, id) {
  try {
    return await fetch(`${API_URL}/users/incomplete`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify({ course, id }),
    });
  } catch (err) {
    return err;
  }
}

//PUT: Disbales a users account - protected account
async function httpDisableUser(id) {
  return await fetch(`${API_URL}/users/disable-account/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
}

// PUT: Activates a users account - protected route
async function httpActivateUser(id) {
  return await fetch(`${API_URL}/users/activate-account/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
}

export {
  httpCompleteCourse,
  httpIncompleteCourse,
  httpLoginUser,
  httpSignUpUser,
  httpGetUser,
  httpGetCertificateInfo,
  httpDisableUser,
  httpActivateUser,
  httpForgotPassword,
  httpResetPassword,
};
