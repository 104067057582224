import { formatNum } from '../../../Helpers/format-phone-number';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import Loader from '../../../Components/Loader/LoaderComponent.component';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createCompany } from '../../../Utils/Companies/createCompany/postCompany';

// Button Functionality for Creating a company
const CreateCompanySubmit = ({
  company,
  image,
  result,
  setResult,
  notes,
  setIsOpen,
  user,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const { phoneMobile, phoneOffice } = company;
    const companyPhone = await formatNum(phoneOffice);
    const mobilephone = await formatNum(phoneMobile);
    const newCompany = {
      ...company,
      active: true,
      notes: notes,
      phoneMobile: mobilephone,
      phoneOffice: companyPhone,
    };
    await createCompany(
      newCompany,
      image,
      setResult,
      setIsOpen,
      setLoading,
      user
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p>{result}</p>
          <br />
          <PrimaryButton
            functionName={handleSubmit}
            span="Create Company"
            disabled={!disabled}
          />
        </div>
      )}
    </>
  );
};

CreateCompanySubmit.propTypes = {
  company: PropTypes.shape({
    phoneMobile: PropTypes.string,
    phoneOffice: PropTypes.string,
  }).isRequired,
  image: PropTypes.string,
  setResult: PropTypes.func.isRequired,
  notes: PropTypes.string,
  setIsOpen: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  result: PropTypes.string,
};

export default CreateCompanySubmit;
