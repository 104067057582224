import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from './Visuals/Colors';
import React from 'react';

//Pages
import NavBar from './Routes/MainComponents/navigation/NavbarComponent.component';
import Footer from './Routes/MainComponents/footer/FooterComponent.component';
import BasePage from './Routes/MainComponents/base/BasePage.component';
import AboutUs from './Routes/AboutUs/AboutUsPage.component';
import ProgramDevelopment from './Routes/ProgramDevelopment/ProgramPage.component';
import Insurance from './Routes/Insurances/InsurancePage.component';
import Resources from './Routes/Resources/ResourcePage.component';
import WCBCertified from './Routes/WcbCertified/WcbPage.component';
import OnlineTraining from './Routes/Online/TrainingOnlinePage.component';
import ClassroomTraining from './Routes/Classroom/TrainingClassroomPage.component';
import NotFound from './Routes/MainComponents/not-found/NotFoundPage.component';
import PrivacyPolicy from './Routes/PrivacyPolicy/PrivacyPolicyPage.component';
import TermsAndConditions from './Routes/Terms&Conditions/TermsAndConditionsPage.component';

// Authentication
import AuthComponent from './Authentication/AuthenticationHome.component';
import ForgotPassword from './Authentication/PasswordReset/ForgotPassword';
import ResetPassword from './Authentication/PasswordReset/ResetPassword';
import Unauthorized from './Unauthorized/UnauthorizedComponent.component';

//Admin: Protected with AuthCheckAdmin
import Admin from './AdminRoutes/Admin/AdminHome.component';
import CreateUser from './AdminRoutes/CreateRoutes/CreateUser/UserCreate.component';
import CreateCompany from './AdminRoutes/CreateRoutes/CreateCompany/CompanyCreate.component';
import CreateCourse from './AdminRoutes/CreateRoutes/CreateCourse/CourseCreate.component';
import EditCourse from './AdminRoutes/EditRoutes/EditCourse/CourseEdit.component';
import EditUser from './AdminRoutes/EditRoutes/EditUser/UserEdit.component';
import EditCompany from './AdminRoutes/EditRoutes/EditCompany/CompanyEdit.component';
import EditManagersAndUsers from './AdminRoutes/EditRoutes/EditManagers/ManagersEdit.componentPage';

//User: Protected with AuthCheckUser
import UserHome from './UserRoutes/UserHomepage/UserHomePage.component';
import UserSettings from './UserRoutes/UserSettings/UserSettingsHome.component';

//Course: Protected with AuthCheckUser
//import BuyCourse from './BuyCourse/BuyCoursePage/buyCourse.component';
import QuizComponent from './Quiz/QuizHomepage.component';
import CourseComplete from './Quiz/Completed/QuizCompletePage.component';
import IncompleteCourse from './Quiz/Incomplete/QuizIncompletePage.component';
import CourseHomepage from './TakeCourse/CourseHomepage.component';
import SingleCourse from './Courses/EachCoursePage.component';
import CourseReview from './UserRoutes/UserCourseReview/CourseReview.component';

// Company: Protected with AuthCheckManager - Imported but commented out until development is finished
import EditManagerOrEmployee from './AdminRoutes/EditRoutes/EditManagers/EditManagerOrEmployee';
import CompanyHome from './CompanyRoutes/CompanyHomepage/CompanyHome.component';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

function App() {
  const queryClient = new QueryClient();

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <NavBar />
            <Routes>
              {/* Main public routes */}
              <Route path="/" element={<BasePage />}>
                <Route index element={<AboutUs />} />
                <Route path="program" element={<ProgramDevelopment />} />
                <Route
                  path="terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="resources" element={<Resources />} />
                <Route path="insurance" element={<Insurance />} />
                <Route path="wcb-certified" element={<WCBCertified />} />
                <Route path="online" element={<OnlineTraining />} />
                <Route path="classroom" element={<ClassroomTraining />} />
                <Route path="login" element={<BasePage />}>
                  <Route index element={<AuthComponent />} />
                  <Route path="forgot-password" element={<ForgotPassword />} />
                  <Route
                    path="reset-password/:token"
                    element={<ResetPassword />}
                  />
                </Route>
                <Route path="unauthorized" element={<Unauthorized />} />
              </Route>
              {/* Admin Routes  */}
              <Route path="admin" element={<BasePage />}>
                <Route index element={<Admin />} />
                <Route path="create-course" element={<CreateCourse />} />
                <Route path="create-company" element={<CreateCompany />} />
                <Route path="create-user" element={<CreateUser />} />
                {/* <Route path='course' element={<AllCourses />} /> */}
                <Route path="edit-user/:id" element={<EditUser />} />
                <Route path="edit-course/:id" element={<EditCourse />} />
                <Route path="edit-company/:id" element={<BasePage />}>
                  <Route index element={<EditCompany />} />
                  <Route
                    path="managers-employees"
                    element={<EditManagersAndUsers />}
                  />
                  <Route
                    path="managers-employees/:userId"
                    element={<EditManagerOrEmployee />}
                  />
                </Route>
              </Route>
              {/* User Routes */}
              <Route path="user" element={<BasePage />}>
                <Route index element={<UserHome />} />
                <Route path="settings/:id" element={<UserSettings />} />
              </Route>

              {/* Course Routes */}
              <Route path="course" element={<BasePage />}>
                <Route index path=":id" element={<SingleCourse />} />
                <Route path="home/:id" element={<CourseHomepage />} />
                <Route path="quiz/:id" element={<QuizComponent />}></Route>
                <Route path="quiz/complete/:id" element={<CourseComplete />} />
                <Route
                  path="quiz/incomplete/:id"
                  element={<IncompleteCourse />}
                />
                <Route path="review/:id" element={<CourseReview />} />
              </Route>

              {/* Company Routes */}
              <Route path="company" element={<BasePage />}>
                <Route index element={<CompanyHome />} />
              </Route>

              {/* <Route path='cart/:id' element={<BuyCourse />} /> */}

              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
