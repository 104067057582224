import styled from 'styled-components';

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  //border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  padding-bottom: ${({ theme }) => theme.spacing.m};
  label {
    width: 40%;
  }
  input,
  textarea {
    width: 60%;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
  width: 100%;
  .seperate {
    width: 50%;
  }
`;
export const EachTag = styled.div`
  width: 100%;
  display: flex;
  /* gap: ${({ theme }) => theme.spacing.m}; */
  padding: ${({ theme }) => theme.spacing.xxs};
  label {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey1};
  }
`;

export const ArrayContainer = styled.div`
  margin-block: ${({ theme }) => theme.spacing.m};
  padding-block: ${({ theme }) => theme.spacing.m};
  /* border-bottom: 1px solid ${({ theme }) => theme.colors.grey}; */
  .mappedElements {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xxs};
  }
  .inputDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing.xl};
    margin-bottom: ${({ theme }) => theme.spacing.m};
  }
  input {
    width: 70%;
  }
  h4 {
    margin-bottom: ${({ theme }) => theme.spacing.m};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
    //text-transform: uppercase;
  }
`;
