import styled from 'styled-components';

export const CourseHomepageContainer = styled.main`
  display: flex;
  background-color: ${({ theme }) => theme.colors.offWhite};
  a {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs};
  }
  a .icon {
    height: 20px;
  }
  @media screen and (width <= 1300px) {
    flex-direction: column-reverse;
    margin: 0 auto;
    /* border: 1px solid red; */
  }
`;

export const CouseHomeLeft = styled.section`
  // border: 1px solid blue;
  width: 70%;
  padding: ${({ theme }) => theme.spacing.m};
  overflow-y: scroll;
  h2 {
    margin-bottom: ${({ theme }) => theme.spacing.m};
  }

  @media screen and (width <= 1300px) {
    /* border: 2px solid green; */
    //margin-inline: ${({ theme }) => theme.spacing.xl};
    width: 100%;
    height: 100vh;
  }
`;

export const CourseHomeRight = styled.aside`
  // border: 1px solid orange;
  width: 30%;
  padding: ${({ theme }) => theme.spacing.m};
  background-color: ${({ theme }) => theme.colors.grey6};
  p {
    color: ${({ theme }) => theme.colors.yellow2};
    font-style: italic;
    margin-block: ${({ theme }) => theme.spacing.m};
  }
  button {
    margin-left: ${({ theme }) => theme.spacing.m};
    border: 1px solid orange;
    margin-top: ${({ theme }) => theme.spacing.m};
    width: 200px;
  }
  @media screen and (width <= 1300px) {
    width: ${({ dissapear }) => (dissapear ? '0%' : '100%')};
    display: ${({ dissapear }) => (dissapear ? 'none' : 'flex')};
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.grey6};
  }
`;
export const BreadcrumbsContainer = styled.div`
  @media screen and (width <= 1300px) {
    display: none;
  }
`;

export const LinksContainer = styled.div`
  padding-left: ${({ theme }) => theme.spacing.m};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xl};
  // margin-top: 1rem;
  .courseStatus {
    color: ${({ theme }) => theme.colors.orange4};
    margin-bottom: ${({ theme }) => theme.spacing.m};
    font-style: italic;
  }
  @media screen and (width <= 1300px) {
    display: none;
  }
`;

export const PresentationContainer = styled.div`
  width: calc(100% - 30px);
  //height: calc(100% - 30px);
  height: 600px;
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.spacing.m};
  /* border: 2px solid ${({ theme }) => theme.colors.grey4}; */
  iframe {
    border: 3px solid ${({ theme }) => theme.colors.grey2};
    border-radius: ${({ theme }) => theme.borderRadius};
  }
  @media screen and (width <= 600px) {
    border: 2px solid purple;
    //width: 900px;
    height: 400px;
  }
`;

export const EachSection = styled.div`
  // border: 1px solid pink;
  height: auto;
  margin-block: ${({ theme }) => theme.spacing.xl};
  ul {
    margin-left: ${({ theme }) => theme.spacing.xxxl};
  }
  a {
    color: ${({ theme }) => theme.colors.orange6};
  }
  li {
    margin-top: ${({ theme }) => theme.spacing.xs};
  }
  a:visited {
    color: ${({ theme }) => theme.colors.grey2};
  }
`;
export const SubsectionContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.m};
  width: 100%;
  align-items: center;
  //border: 2px solid green;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey1};
  h4 {
    margin-top: ${({ theme }) => theme.spacing.xs};
  }
`;
