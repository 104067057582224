import { MainContainer, MainSection } from '../MainRoute.styles';
import React from 'react';
import { Helmet } from 'react-helmet';

const ProgramDevelopment = () => {
  return (
    <MainContainer>
      <Helmet>
        <title>Program Development | Yoda Safety Services</title>
        <meta
          name="Program Development"
          content="About our program development at yoda safety services"
        />
      </Helmet>
      <h1>Health & Safety Program Development</h1>
      <MainSection>
        <p>
          {' '}
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Officia ea
          ipsum nulla rem, dolore inventore. Saepe nesciunt adipisci
          repudiandae. Quisquam temporibus recusandae ipsum tenetur sapiente
          eveniet aut expedita praesentium dolor.
        </p>
      </MainSection>
    </MainContainer>
  );
};

export default ProgramDevelopment;
