import styled from 'styled-components';

// prettier-ignore
export const QuizContainer = styled.div`
  width: 95%;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.m};
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xss};
  padding: ${({ theme }) => theme.spacing.xs};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.offWhite};
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  .item {
    font-size: 1.4rem;
  }
  .list {
    font-size: 1.4rem;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin: ${({ theme }) => theme.spacing.l};
`;

export const QuestionContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.m};
  display: flex;
  gap: ${({ theme }) => theme.spacing.m};
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.m};
  width: 95%;
  border-radius: ${({ theme }) => theme.borderRadius};
  margin: ${({ theme }) => theme.spacing.m} auto;
  /* border: 3px solid ${({ theme }) => theme.colors.grey}; */
  box-shadow: ${({ theme }) => theme.shadows.shadow3};
  p {
    font-size: ${({ theme }) => theme.spacing.m};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
    color: ${({ theme }) => theme.colors.grey5};
    font-size: 1.2rem;
  }
  span {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.grey3};
    font-style: italic;
    font-size: 1.2rem;
  }
`;
