import Loader from '../../../Components/Loader/LoaderComponent.component';
import React, { useState } from 'react';
import DestructiveButton from '../../../Components/Buttons/DestructiveButton/DestructiveButton.component';
import PropTypes from 'prop-types';
import { deleteCourse } from '../../../Utils/Courses/editCourse/deleteCourse';

// Deletes a course
const DeleteCourseButton = ({ setSuccessState, setIsOpen, id }) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!id) {
      setSuccessState('ID is required');
      setLoading(false);
      setIsOpen(true);
      return;
    }
    await deleteCourse(id, setIsOpen, setLoading, setSuccessState);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <DestructiveButton
          functionName={handleDeleteSubmit}
          span="Delete Course"
        />
      )}
    </>
  );
};

DeleteCourseButton.propTypes = {
  id: PropTypes.string,
  setSuccessState: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default DeleteCourseButton;
