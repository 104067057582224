import { InputContainer } from './FormElements.styles';
import React from 'react';
import PropTypes from 'prop-types';
import AsteriskIcon from '../../Visuals/Icons/AsteriskIcon.icon';

// Textarea Component: title, placeholder, value, setValue
const TextareaDiv = ({
  title = '',
  requiredInput = false,
  value = '',
  placeholder = '',
  setValue,
  containerClassName = '',
}) => {
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <InputContainer className={containerClassName}>
      <label>
        {title}
        {requiredInput && <AsteriskIcon />}
      </label>
      <textarea
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
    </InputContainer>
  );
};
TextareaDiv.propTypes = {
  title: PropTypes.string.isRequired,
  requiredInput: PropTypes.bool,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  containerClassName: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

export default TextareaDiv;
