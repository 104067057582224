import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import React, { useState } from 'react';
import Loader from '../../../Components/Loader/LoaderComponent.component';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createManagerOrUser } from '../../../Utils/Companies/Managers-Employees/createManager/createManager';

const CreateManagerSubmit = ({ user, type }) => {
  const [isLoading, setLoading] = useState(false);
  const [result, setResult] = useState('');
  const { id } = useParams();

  // Function to submit the form
  const createManager = async (e) => {
    e.preventDefault();
    setLoading(true);

    const fullUser = {
      ...user,
      type,
    };
    await createManagerOrUser(fullUser, id, setResult, setLoading);
  };

  return (
    <>
      {result && <p>{result}</p>}
      {isLoading ? (
        <Loader />
      ) : (
        <PrimaryButton functionName={createManager} span="Create" />
      )}
    </>
  );
};

CreateManagerSubmit.propTypes = {
  user: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default CreateManagerSubmit;
