import { useParams } from 'react-router-dom';
import Auth from '../../Helpers/auth';
import { httpBuyCourse } from '../../Hooks/courses.hooks';
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton.component';
import React, { useState } from 'react';

// Component for buying the course
const BuyButton = () => {
  const { id } = useParams();
  const { data } = Auth.getProfile();
  const [err, setErr] = useState('');
  const [success, setSuccess] = useState('');

  const buyCourse = async () => {
    const res = await httpBuyCourse({ courseId: id, userId: data.id });
    if (res.ok) {
      setSuccess('Course has been purchased');
    }
    const d = await res.json();
    setErr(d.msg);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {success && <p className="success">{success}</p>}
      {err && <p className="error">{err}</p>}
      <PrimaryButton functionName={buyCourse} span="Buy Course" />
    </div>
  );
};

export default BuyButton;
