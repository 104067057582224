import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { UnauthMain } from './UnauthorizedStyles.styles';
import React from 'react';
import { Helmet } from 'react-helmet';

const Unauthorized = () => {
  const navigate = useNavigate();
  const goHome = (e) => {
    e.preventDefault();
    navigate('/');
  };
  return (
    <UnauthMain>
      <Helmet>
        <title>Unauthorized | Yoda Safety Services</title>
        <meta name="Unauthorized" content="Unauthorized to visit this page" />
      </Helmet>
      <div>
        <h2>You are unauthroized</h2>
        <PrimaryButton functionName={goHome} span="Go Home" />
      </div>
    </UnauthMain>
  );
};

export default Unauthorized;
