import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import AdminTabsComponent from './AdminTabsComponent.component';
import {
  LeftAdmin,
  RightAdmin,
  TopLeftAdmin,
  BottomLeftAdmin,
  AdminContainer,
  AdminTable,
} from './AdminStyles.styles';
import CoursesTab from './Courses/CoursesTabComponent.component';
import UsersTab from './Users/UserTabComponent.component';
import CompaniesTab from './Companies/CompaniesTabComponent.component';
import { AuthCheckAdmin } from '../../Utils/AuthCheck/AdminCheck.auth';
import { Helmet } from 'react-helmet';
import UserIcon from '../../Visuals/Icons/UserIcon.icon';
import PresentationIcon from '../../Visuals/Icons/PresentationIcon.icon';
import HomeIcon from '../../Visuals/Icons/HomeIcon.icon';
import OfficeIcon from '../../Visuals/Icons/OfficeIcon.icon';
import LogoutIcon from '../../Visuals/Icons/LogoutIcon.icon';
import Auth from '../../Helpers/auth';

// prettier-ignore
const Admin = () => {
  const [showCompanies, setShowCompanies] = useState(true);
  const [showCourses, setShowCourses] = useState(false);
  const [showUsers, setShowUsers] = useState(false);

  return (
    <AuthCheckAdmin>
      <Helmet>
        <title>Admin Home | Yoda Safety Services</title>
        <meta
          name="Admin Home"
          content="Admin homepage for creating, updating and deleting"
        />
      </Helmet>
      <AdminContainer>
        <LeftAdmin>
          <TopLeftAdmin>
            <h2>Admin Dashboard</h2>
          </TopLeftAdmin>
          <BottomLeftAdmin>
            <Link
              className="subLink"
              to="/admin/create-user"
              style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
            >
              <UserIcon />
              Create User
            </Link>
            <Link className="subLink" to="/admin/create-company"
            style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
            >
              <OfficeIcon />
              Create Company
            </Link>
            <Link className="subLink" to="/admin/create-course" 
            style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
            >
              <PresentationIcon />
              Create Course
            </Link>
            <Link className="subLink" to="/user"
            style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
            >
              <HomeIcon />
              User Home
            </Link>
            <Link
          onClick={(e) => {
            e.preventDefault();
            Auth.logout();
            window.location.assign('/');
          }}
          title="Logout"
          className="subLink"
          to="/about"
          style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
        >
          <LogoutIcon />
          Logout
        </Link>
          </BottomLeftAdmin>
        </LeftAdmin>
        <RightAdmin>
          <AdminTabsComponent
            setShowCompanies={setShowCompanies}
            showCompanies={showCompanies}
            setShowCourses={setShowCourses}
            showCourses={showCourses}
            setShowUsers={setShowUsers}
            showUsers={showUsers}
          />
          <AdminTable>
            {showCompanies && <CompaniesTab />}
            {showCourses && <CoursesTab />}
            {showUsers && <UsersTab />}
          </AdminTable>
        </RightAdmin>
      </AdminContainer>
    </AuthCheckAdmin>
  );
};

export default Admin;
