import styled from 'styled-components';

// prettier-ignore
export const OuterContainer = styled.main`
  display: flex;
  width: 100%;
  @media screen and (width <= 900px) {
    flex-direction: column;
  }
`;

export const LeftContainer = styled.aside`
  width: 30%;
  padding: ${({ theme }) => theme.spacing.xs};
  background-color: ${({ theme }) => theme.colors.grey6};
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  h2 {
    color: ${({ theme }) => theme.colors.offWhite};
    text-transform: capitalize;
    font-weight: 400;
  }
  h2::after {
    width: 0%;
  }
  @media screen and (width <= 900px) {
    width: 100%;
    height: 16vh;
  }
`;
export const UserInfo = styled.div`
  border-bottom: 3px solid ${({ theme }) => theme.colors.yellow5};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.m};
  padding: 1rem 0 1rem 1rem;
  img {
    border-radius: 50%;
    width: 125px;
    height: 125px;
    box-shadow: 0 0 10px 0 ${({ theme }) => theme.colors.grey3};
  }
  @media screen and (width <= 900px) {
    width: 100%;
    height: 60%;
    flex-direction: row;
    img {
      width: 50px;
      height: 50px;
    }
  }
  @media screen and (width <= 600px) {
    // padding: 1rem 0 2rem 1rem;
    img {
      width: 35px;
      height: 35px;
    }
  }
`;
export const UserLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing.m};
  gap: ${({ theme }) => theme.spacing.m};
  margin-top: ${({ theme }) => theme.spacing.xl};
  /* border: 1px solid orange; */
  @media screen and (width <= 900px) {
    width: 100%;
    height: 50%;
    flex-direction: row;
    margin-left: 0;
    margin-top: ${({ theme }) => theme.spacing.m};
    justify-content: space-between;
    a {
      padding: 0;
      margin: 0;
      font-size: 1rem;
    }
  }
  @media screen and (width <= 600px) {
    margin-top: ${({ theme }) => theme.spacing.m};
    align-items: center;
    a {
      font-size: 0.8rem;
    }
  }
`;

export const RightContainer = styled.section`
  width: 70%;
  background-color: ${({ theme }) => theme.colors.grey};
  /* padding: ${({ theme }) => theme.spacing.m}; */
  overflow-y: scroll;
  /* border: 1px solid yellow; */
  @media screen and (width <= 900px) {
    width: 100%;
    height: 90vh;
  }
`;

export const TopRightContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xl};
  background-color: ${({ theme }) => theme.colors.grey5};
  padding-top: ${({ theme }) => theme.spacing.xl};
  padding-left: ${({ theme }) => theme.spacing.l};
  padding-right: ${({ theme }) => theme.spacing.l};
  overflow-x: scroll;
  /* border: 1px solid green; */
  @media screen and (width <= 800px) {
    display: none;
  }
`;

export const CourseContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.m};
  overflow-y: scroll;
  /* border: 1px solid blue; */
`;
