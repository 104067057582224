import PropTypes from 'prop-types';
import React from 'react';
import InputText from '../../../../Components/Form/InputText';
import ArrayInput from '../../../../Components/Form/ArrayInput';
import TextareaDiv from '../../../../Components/Form/Textarea';
import InputNumber from '../../../../Components/Form/InputNumber';
import PdfInput from '../../../../Components/Form/PdfInput.component';
import ImageInput from '../../../../Components/Form/ImageInput.component';
import PlayIcon from '../../../../Visuals/Icons/PlayIcon.icon';
import LinksIcon from '../../../../Visuals/Icons/LinkIcon.icon';

const InputEditCourses = ({ incomingProps }) => {
  const {
    courseName = '',
    description = '',
    price = 0,
    expiryDate = 0,
    certificatePrefix = '',
    notes = '',
    video = '',
    doc = '',
    setCertificatePrefix,
    setCourseName,
    setDescription,
    setPrice,
    setNotes,
    videoLinks = [],
    documentation = [],
    setExpiryDate,
    setVideo,
    setDoc,
    setVideoLinks,
    setDocumentation,
    pdf = '',
    setNewImage,
    setNewPdf,
    image = '',
  } = incomingProps;
  console.log('Incoming Props', incomingProps);
  return (
    <>
      {/* Course Name */}
      <InputText
        value={courseName}
        requiredInput={true}
        title="Course Name"
        name="courseName"
        setValue={setCourseName}
        containerClassName="formInputUnderline"
      />
      {/* Description */}
      <TextareaDiv
        requiredInput={true}
        value={description}
        name="description"
        title="Course Description"
        setValue={setDescription}
        containerClassName="formInputUnderline"
      />
      {/* Price */}
      <InputNumber
        requiredInput={true}
        value={price}
        name="price"
        title="Course Price"
        setValue={setPrice}
        containerClassName="formInputUnderline"
      />
      {/* Expiry Date */}
      <InputNumber
        value={expiryDate}
        title="Expiry Date"
        name="expiryDate"
        detail="in years"
        setValue={setExpiryDate}
        requiredInput={true}
        containerClassName="formInputUnderline"
      />
      {/* Certificate Prefix */}
      <InputText
        value={certificatePrefix}
        title="Certificate Prefix"
        detail="format: FP-"
        name="certificatePrefix"
        setValue={setCertificatePrefix}
        requiredInput={true}
        placeholder="FP-"
        containerClassName="formInputUnderline"
      />
      {/* Notes about the course */}
      <TextareaDiv
        value={notes}
        name="notes"
        title="Notes"
        setValue={setNotes}
        containerClassName="formInputUnderline"
      />
      {/* Video Links */}
      <ArrayInput
        label="Video Links"
        single={video}
        name="video"
        arrayItems={videoLinks}
        setSingle={setVideo}
        setArray={setVideoLinks}
        containerClassName="formInputUnderline"
        icon={<PlayIcon />}
      />
      {/* Documentation */}
      <ArrayInput
        label="Documentation"
        name="documentation"
        single={doc}
        arrayItems={documentation}
        setSingle={setDoc}
        setArray={setDocumentation}
        icon={<LinksIcon />}
        containerClassName="formInputUnderline"
      />
      {/* PDF Upload */}
      <PdfInput
        pdf={pdf}
        name="pdf"
        setNewPdf={setNewPdf}
        containerClassName="formInputUnderline"
      />
      {/* Image Input */}
      <ImageInput
        image={image}
        name="image"
        setNewImage={setNewImage}
        containerClassName="formInputUnderline"
      />
    </>
  );
};

InputEditCourses.propTypes = {
  incomingProps: PropTypes.shape({
    courseName: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    expiryDate: PropTypes.number,
    certificatePrefix: PropTypes.string,
    notes: PropTypes.string,
    video: PropTypes.string,
    doc: PropTypes.string,
    setCertificatePrefix: PropTypes.func,
    setCourseName: PropTypes.func,
    setDescription: PropTypes.func,
    setPrice: PropTypes.func,
    setNotes: PropTypes.func,
    videoLinks: PropTypes.arrayOf(PropTypes.string),
    documentation: PropTypes.arrayOf(PropTypes.string),
    setExpiryDate: PropTypes.func,
    setVideo: PropTypes.func,
    setDoc: PropTypes.func,
    setVideoLinks: PropTypes.func,
    setDocumentation: PropTypes.func,
    pdf: PropTypes.string,
    setNewImage: PropTypes.func,
    setNewPdf: PropTypes.func,
    image: PropTypes.string,
  }),
};

export default InputEditCourses;
