import { useParams } from 'react-router-dom';
import React from 'react';
import {
  QuizCompleteContainer,
  ResultsContainer,
} from '../Completed/QuizComplete.styles';
import { httpGetSingleCourse } from '../../Hooks/courses.hooks';
import { useQuery } from '@tanstack/react-query';
import UserIncompleteComponent from '../DisplayUserInfo/DisplayUserIncompleteComponent.component';
import { AuthCheckUser } from '../../Utils/AuthCheck/UserCheck.auth';
import Loader from '../../Components/Loader/LoaderComponent.component';
import { Helmet } from 'react-helmet';
import FinishedPicture from '../../Visuals/Images/finished.svg';
import ReviewQuiz from '../ReviewQuiz/ReviewQuiz.component';
// Component for seeing the answers for incompleted course
const IncompleteCourse = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useQuery({
    queryKey: ['userId', id],
    queryFn: () => httpGetSingleCourse(id),
    enabled: !!id,
    retry: 5,
  });

  if (error) {
    return <h3>An error has occured</h3>;
  }
  if (isLoading) {
    return <Loader />;
  }

  return (
    <AuthCheckUser>
      <Helmet>
        <title>Incompleted Course | Yoda Safety Services</title>
        <meta
          name="Incomplete Course"
          content="Course has not been completed"
        />
      </Helmet>
      <QuizCompleteContainer>
        <ResultsContainer>
          <img src={FinishedPicture} alt="hexagons" />
          <UserIncompleteComponent id={id} />
        </ResultsContainer>
        {/* For reviewing the quiz */}
        <ReviewQuiz dataCourse={data} />
      </QuizCompleteContainer>
    </AuthCheckUser>
  );
};

export default IncompleteCourse;
