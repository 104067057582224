import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import InputTextNested from '../../../../Components/Form/InputTextNested';
import InputText from '../../../../Components/Form/InputText';
import { DateAndTime } from '../../../CreateRoutes/CreateForm.styles';

// prettier-ignore
const InputsForEditUser = ({
  user = {},
  password = '',
  setPassword,
  setUser,
}) => {
  const formattedCreatedAt = user?.createdAt
    ? format(new Date(user?.createdAt), 'MMMM dd yyyy,  HH:mm')
    : '';
  const formattedUpdatedAt = user?.updatedAt
    ? format(new Date(user?.updatedAt), 'MMMM dd yyyy,  HH:mm')
    : '';

  return (
    <>
      <InputTextNested
        title="First Name"
        value={user.firstName}
        name="firstName"
        nestedValue={setUser}
        upperValue={user}
        requiredInput={true}
        containerClassName="formInputUnderline"
      />
      <InputTextNested
        title="Last Name"
        value={user.lastName}
        name="lastName"
        nestedValue={setUser}
        upperValue={user}
        requiredInput={true}
        containerClassName="formInputUnderline"
      />
      <InputTextNested
        title="Email"
        value={user.email}
        name="email"
        nestedValue={setUser}
        upperValue={user}
        requiredInput={true}
        containerClassName="formInputUnderline"
      />
      <InputText
        title="Password"
        value={password}
        name="password"
        setValue={setPassword}
        containerClassName="formInputUnderline"
      />
      <DateAndTime>
        <p>
          Type: <span>{user?.type}</span>
        </p>
        {user?.company?.length < 0 ? (
          <p>
            Company: <span>{user?.company[0]?.companyName}</span>
          </p>
        ) : (
          <p>Company: <span>User has not been assigned to a company</span></p>
        )}
        <p>
          Created At: <span>{formattedCreatedAt}</span>
        </p>
        <p>
          Updated At: <span>{formattedUpdatedAt}</span>
        </p>
        <p>
        Courses:{' '}
        {user?.courses?.length < 1 ? <span>User has no courses</span> : null}
        {user?.courses?.map((c) => {
          return (
            <span
              style={{ color: c?.complete ? 'green' : 'red' }}
              key={c?.courseId}
            >
              {c?.courseName},{' '}
            </span>
          );
        })}
      </p>
      </DateAndTime>
    </>
  );
};

InputsForEditUser.propTypes = {
  user: PropTypes.object,
  password: PropTypes.string,
  setPassword: PropTypes.func,
  setUser: PropTypes.func,
};

export default InputsForEditUser;
