import { httpCreateCompany, httpCompanyLogo } from '../../../Hooks/admin.hooks';

export const createCompany = async (
  newCompany,
  image,
  setResult,
  setIsOpen,
  setLoading,
  user
) => {
  try {
    const res = await httpCreateCompany(newCompany, user);
    const data = await res.json();
    if (res.ok) {
      if (image) {
        const formData = new FormData();
        formData.append('logo', image);
        formData.append('company', newCompany.companyName);
        const res = await httpCompanyLogo(formData);
        if (res.ok) {
          setResult('Company has been created with logo');
        } else {
          setResult(
            'An error occurred while creating the company, issue with logo'
          );
        }
      }
    }
    setResult(data.msg);
  } catch (err) {
    setResult('An error has occured creating the company', err.msg);
  } finally {
    setLoading(false);
    setIsOpen(true);
  }
};
