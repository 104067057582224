import PropTypes from 'prop-types';
import React from 'react';
import InputTextNested from '../../../../Components/Form/InputTextNested';

const CompanyAdmin = ({ title, user, setUser, type, setType }) => {
  const typeTemplate = (
    <div
      style={{
        marginBlock: '2rem',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--offWhite)',
      }}
    >
      <label htmlFor="type" style={{ paddingRight: '2rem' }}>
        Type
      </label>
      <select
        name="type"
        id="type"
        value={type}
        onChange={(e) => setType(e.target.value)}
      >
        <option value="Manager">Manager</option>
        <option value="User">Employee</option>
      </select>
    </div>
  );

  return (
    <>
      {title ? <h3 style={{ textTransform: 'uppercase' }}>{title}</h3> : null}
      <InputTextNested
        title="First Name"
        value={user.firstName}
        name="firstName"
        requiredInput={true}
        nestedValue={setUser}
        upperValue={user}
        containerClassName="formInputUnderline"
      />
      <InputTextNested
        title="Last Name"
        value={user.lastName}
        name="lastName"
        requiredInput={true}
        nestedValue={setUser}
        upperValue={user}
        containerClassName="formInputUnderline"
      />
      <InputTextNested
        title="Email"
        value={user.email}
        name="email"
        requiredInput={true}
        nestedValue={setUser}
        upperValue={user}
        containerClassName="formInputUnderline"
      />
      <InputTextNested
        title="Password"
        value={user.password}
        name="password"
        requiredInput={true}
        nestedValue={setUser}
        upperValue={user}
        containerClassName="formInputUnderline"
      />
      {type ? typeTemplate : null}
    </>
  );
};
CompanyAdmin.propTypes = {
  title: PropTypes.string,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  setUser: PropTypes.func.isRequired,
  type: PropTypes.string,
  setType: PropTypes.func,
};

export default CompanyAdmin;
