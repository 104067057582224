import React from 'react';
import PropTypes from 'prop-types';

const SidebarQuestions = ({ questions, answeredQuestions }) => {
  return (
    <>
      {questions?.map((question, index) => {
        return (
          <div style={{ marginTop: '0.8rem' }} key={question?.id}>
            <a
              href={`#${question?.id}`}
              style={{
                color: answeredQuestions[question?.id] ? '#EDA845' : '#D1CECE',
              }}
            >
              Question #{index + 1}
            </a>
          </div>
        );
      })}
    </>
  );
};
SidebarQuestions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  answeredQuestions: PropTypes.object,
};

export default SidebarQuestions;
