import { ModalContainer } from './ModalStyles.styles';
import SecondaryButton from '../Buttons/SecondaryButton/SecondaryButton.component';
import PropTypes from 'prop-types';
import React from 'react';

// Pop up modal
const Modal = ({ children, onClose }) => {
  return (
    <ModalContainer>
      <div className="modal swing-in-top-fwd">
        <div className="topClose">
          <h3 onClick={onClose}>X</h3>
        </div>
        <div className="content">
          {children}
          <SecondaryButton functionName={onClose} span="Close" />
        </div>
      </div>
    </ModalContainer>
  );
};
Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default Modal;
