import { httpBuyCourse } from '../../../../Hooks/courses.hooks';

export const freeCourse = async (course, setSuccessState, setLoading) => {
  setLoading(true);
  try {
    const res = await httpBuyCourse(course);
    const data = await res.json();
    if (res.ok) {
      setSuccessState('Course has been purchased');
    } else {
      setSuccessState(data.msg);
    }
  } catch (error) {
    setSuccessState('An error occurred while purchasing the course');
    console.error('Error purcashing course:', error);
  } finally {
    setLoading(false);
  }
};
