import { MainContainer, MainSection } from '../MainRoute.styles';
import React from 'react';
import { Helmet } from 'react-helmet';

const Resources = () => {
  return (
    <MainContainer>
      <Helmet>
        <title>Resources | Yoda Safety Services</title>
        <meta name="Resources" content="Resources from yoda safety services" />
      </Helmet>
      <h1>Resources</h1>
      <MainSection>
        <p>
          {' '}
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Officia ea
          ipsum nulla rem, dolore inventore. Saepe nesciunt adipisci
          repudiandae. Quisquam temporibus recusandae ipsum tenetur sapiente
          eveniet aut expedita praesentium dolor.
        </p>
      </MainSection>
    </MainContainer>
  );
};

export default Resources;
