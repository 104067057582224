import React from 'react';
import PropTypes from 'prop-types';
import { TertiaryButtonContainer } from './TertiaryButton.styles';

// Tertiary Button Component
const TertiaryButton = ({ functionName, span }) => {
  return (
    <TertiaryButtonContainer onClick={functionName}>
      <span>{span}</span>
    </TertiaryButtonContainer>
  );
};
TertiaryButton.propTypes = {
  functionName: PropTypes.func.isRequired,
  span: PropTypes.string.isRequired,
};

export default TertiaryButton;
