import { NavLink, Link } from 'react-router-dom';
import Logo from '../../../Visuals/Images/Logo.png';
import { Header } from './NavbarStyles.styles';
import OuterBurger from './OuterBurgerComponent.component';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import Auth from '../../../Helpers/auth';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import LoginIcon from '../../../Visuals/Icons/LoginIcon.icon';
// import LogoutIcon from '../../../Visuals/Icons/LogoutIcon.icon';
import HomeIcon from '../../../Visuals/Icons/HomeIcon.icon';

const NavBar = () => {
  const navigate = useNavigate();

  return (
    <Header>
      <nav className="topNav">
        <div className="first">
          <OuterBurger />
        </div>
        <div className="second">
          <NavLink to="/">
            <img
              className="logoImage"
              src={Logo}
              alt="Yoda Safety Services Logo"
            />
          </NavLink>
        </div>

        <div className="third">
          {Auth.loggedIn() ? (
            <>
              <div className="mobile">
                {/* <button className='primaryButton boxShadow' onClick={(e) => {
                        e.preventDefault();
                        Auth.logout();
                    }}><span>LOGOUT</span></button> */}
                <NavLink to="/user" title="User Home">
                  <HomeIcon />
                </NavLink>

                {/* <div
                  onClick={(e) => {
                    e.preventDefault();
                    Auth.logout();
                    window.location.assign('/');
                  }}
                  title="Logout"
                >
                  <LogoutIcon />
                </div> */}
              </div>
              {/* Desktop */}
              <div className="desktop">
                <NavLink to="/user" title="User Home">
                  <HomeIcon />
                </NavLink>
                {/* <PrimaryButton
                  functionName={(e) => {
                    e.preventDefault();
                    Auth.logout();
                    window.location.assign('/');
                  }}
                  span="LOGOUT"
                /> */}
              </div>
            </>
          ) : (
            //   If not logged in
            <>
              {/* Mobile Login button */}
              {(process.env.REACT_APP_PRODUCTION === undefined ||
                process.env.REACT_APP_PRODUCTION === 'false') && (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/login');
                  }}
                  className="mobile"
                  title="Login"
                >
                  <LoginIcon />
                </div>
              )}
              {/* Desktop login button */}
              {(process.env.REACT_APP_PRODUCTION === undefined ||
                process.env.REACT_APP_PRODUCTION === 'false') && (
                <div className="desktop">
                  <PrimaryButton
                    functionName={(e) => {
                      e.preventDefault();
                      navigate('/login');
                    }}
                    span="LOGIN"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </nav>
      {(process.env.REACT_APP_PRODUCTION === undefined ||
        process.env.REACT_APP_PRODUCTION === 'false') && (
        <div className="bottomNav">
          {Auth.loggedIn() ? (
            <nav className="links">
              <NavLink to="/">About Us</NavLink>
              <div className="dropdown">
                <NavLink to="/online" className="dropbtn">
                  Training
                </NavLink>
                <div className="dropdown-content boxShadow">
                  <Link to="/online">Online</Link>
                  <Link to="/classroom">Classroom</Link>
                </div>
              </div>
              <NavLink to="/program">Program Development</NavLink>
              <NavLink to="/wcb-certified">
                NS WCB Safety Certified Provider
              </NavLink>
              <NavLink to="/insurance">Insured</NavLink>
              <NavLink to="/resources">Resources</NavLink>
              {/* <NavLink to="/online">Training</NavLink> */}
              {/* <NavLink to="/user">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon icon-home"><path className="primary" d="M9 22H5a1 1 0 0 1-1-1V11l8-8 8 8v10a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1zm3-9a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                <path className="secondary" d="M12.01 4.42l-8.3 8.3a1 1 0 1 1-1.42-1.41l9.02-9.02a1 1 0 0 1 1.41 0l8.99 9.02a1 1 0 0 1-1.42 1.41l-8.28-8.3z"/></svg>
                </NavLink> */}
            </nav>
          ) : (
            <nav className="links">
              <NavLink to="/">About Us</NavLink>
              <div className="dropdown">
                <NavLink to="/online" className="dropbtn">
                  Training
                </NavLink>
                <div className="dropdown-content boxShadow">
                  <Link to="/online">Online</Link>
                  <Link to="/classroom">Classroom</Link>
                </div>
              </div>
              <NavLink to="/program">Program Development</NavLink>
              <NavLink to="/wcb-certified">
                NS WCB Safety Certified Provider
              </NavLink>
              <NavLink to="/insurance">Insured</NavLink>
              <NavLink to="/resources">Resources</NavLink>
              {/* <NavLink to="/online">Training</NavLink> */}
            </nav>
          )}
        </div>
      )}
    </Header>
  );
};

export default NavBar;
