import {
  CourseHomepageContainer,
  BreadcrumbsContainer,
  CourseHomeRight,
  LinksContainer,
  SubsectionContainer,
  CouseHomeLeft,
  PresentationContainer,
  EachSection,
} from './CourseHome.styles';
import { httpGetSingleCourse } from '../Hooks/courses.hooks';
import { useParams, useNavigate } from 'react-router-dom';
import React from 'react';
import BreadCrumbs from '../Components/Breadcrumbs/BreadcrumbsComponent.component';
import { useQuery } from '@tanstack/react-query';
import PrimaryButton from '../Components/Buttons/PrimaryButton/PrimaryButton.component';
import NotFound from '../Routes/MainComponents/not-found/NotFoundPage.component';
import { AuthCheckUser } from '../Utils/AuthCheck/UserCheck.auth';
import PresentationIcon from '../Visuals/Icons/PresentationIcon.icon';
import LinkIcon from '../Visuals/Icons/LinkIcon.icon';
import PlayIcon from '../Visuals/Icons/PlayIcon.icon';
import Loader from '../Components/Loader/LoaderComponent.component';
import { Helmet } from 'react-helmet';
// Taking the course the homepage that displays the presentation, documentation and video links
const CourseHomepage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, error, isLoading } = useQuery({
    queryKey: ['userId', id],
    queryFn: () => httpGetSingleCourse(id),
    enabled: !!id,
    retry: 5,
  });

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <h3>An error has occured</h3>;
  }

  return (
    <AuthCheckUser>
      <Helmet>
        <title>
          {data?.courseName ? `${data?.courseName} Home ` : 'Course Home '}|
          Yoda Safety Services
        </title>
        <meta name="Course homepage" content="Homepage for taking a course" />
      </Helmet>
      <CourseHomepageContainer>
        <CourseHomeRight>
          <LinksContainer>
            <BreadcrumbsContainer>
              <BreadCrumbs one="Home" oneLink="/user" two={data?.courseName} />
            </BreadcrumbsContainer>
            <a href="#presentation">
              <PresentationIcon /> Presentation
            </a>
            <a href="#documentation">
              <LinkIcon />
              Documentation
            </a>
            <a href="#videos">
              <PlayIcon />
              Video Links
            </a>
            <span className="courseStatus">Course is currently incomplete</span>
          </LinksContainer>
          <PrimaryButton
            functionName={(e) => {
              e.preventDefault();
              navigate(`/course/quiz/${id}`);
            }}
            span="Take Quiz"
          />
        </CourseHomeRight>
        <CouseHomeLeft>
          {!data.courseName ? (
            <>
              <NotFound />
            </>
          ) : (
            <>
              <h2>{data?.courseName}</h2>
              <p>{data?.description}</p>
              <EachSection>
                <SubsectionContainer id="presentation">
                  <PresentationIcon />
                  <h3>Presentation</h3>
                </SubsectionContainer>
                <PresentationContainer>
                  {/* #toolbar=0 */}
                  <iframe
                    src={`${data?.pdf}`}
                    title="presentation"
                    width="100%"
                    height="100%"
                  />
                </PresentationContainer>
              </EachSection>
              <EachSection>
                <SubsectionContainer id="documentation">
                  <LinkIcon />
                  <h3>Documentation</h3>
                </SubsectionContainer>
                <ul>
                  {/* The links here may need to be changed to LINK */}
                  {data?.documentation?.map((l) => {
                    return (
                      <li key={l}>
                        <a
                          href={l}
                          target="_blank"
                          rel="noreferrer"
                          alt={'Documentation link to' + l}
                        >
                          {l}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </EachSection>
              <EachSection>
                <SubsectionContainer id="videos">
                  <PlayIcon />
                  <h3>Videos</h3>
                </SubsectionContainer>
                <ul>
                  {data?.videoLinks?.map((l) => {
                    return (
                      <li key={l}>
                        <a
                          href={l}
                          onChange={(e) => e.target}
                          target="_blank"
                          rel="noreferrer"
                          alt={'Video link to ' + l}
                        >
                          {l}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </EachSection>
            </>
          )}
        </CouseHomeLeft>
      </CourseHomepageContainer>
    </AuthCheckUser>
  );
};
export default CourseHomepage;
