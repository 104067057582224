import React, { useState, useEffect } from 'react';
import { httpGetSingleCourse } from '../Hooks/courses.hooks';
import { useParams } from 'react-router-dom';
import {
  BreadcrumbContainer,
  SingleCourseContainer,
  SingleCardContainer,
  SingleCourseImage,
  SingleCourseInfo,
  SingleTop,
  SingleBottom,
  SingleButton,
} from './EachCourse.styles';
import BreadCrumbs from '../Components/Breadcrumbs/BreadcrumbsComponent.component';
import BuyButton from '../Components/Form/BuyButtonComponent.component';
import { AuthCheckUser } from '../Utils/AuthCheck/UserCheck.auth';
import Auth from '../Helpers/auth';
import { Helmet } from 'react-helmet';
import ImportantIcon from '../Visuals/Icons/ImportantIcon.icon';
import InfoCard from '../Components/InfoCard/InfoCard.component';
// Showing a single course to purchase
// prettier-ignore
const SingleCourse = () => {
  const { id } = useParams();
  const [course, setCourse] = useState();
  const [err, setErr] = useState('');

  useEffect(() => {
    const FetchCourse = async () => {
      const data = await httpGetSingleCourse(id);
      if (!data) setErr('Error getting the course');
      setCourse(data);
    };
    FetchCourse();
  }, [id]);

  return (
    <AuthCheckUser>
      <Helmet>
              <title>{course?.courseName ? `${course?.courseName}` : 'Course '} | Yoda Safety Services</title>
              <meta name="About Course" content="Course details" />
            </Helmet>
      <SingleCourseContainer>
        <BreadcrumbContainer>
          <BreadCrumbs
            one="courses"
            oneLink="/online"
            two={course?.courseName}
          />
        </BreadcrumbContainer>
        <SingleCardContainer>
          <SingleTop>
            <SingleCourseImage>
              {err && <p>{err}</p>}
              <img src={course?.image} alt="course" />
            </SingleCourseImage>
            <SingleCourseInfo>
              <div className="courseTop">
                <h1>{course?.courseName}</h1>
                <h2>${course?.price}</h2>
              </div>

              <div className="courseMiddle">
                <InfoCard paragraph="With each course you will start with a detailed PowerPoint presentation that provides the information needed. 
                Alongside the presentation, you will have videos and documentation that dives more into the subject matter.
                After reviewing the course material, you will be able to take a quiz to test your knowledge. To pass the quiz you must get 60% or higher.
                If you do not pass the quiz, you will have the opportunity to take the quiz as many times as needed. 
                Upon passing the quiz, you will receive a certificate of completion."/>
                {/* <div className="courseInfo">
                  <div className="infoIconContainer">
              </div>
              </div> */}
              <p>
              <ImportantIcon />
                  Expires in {' '}
                    {course?.expiryDate} years
                </p>
                </div>

                <div className="industries">
                  <h4>Industries:</h4>
                  <ul style={{ paddingLeft: '2rem', listStyleType: 'square' }}>
                    {course?.tags?.map((t, x) => (
                      <li key={x}>{t}</li>
                    ))}
                  </ul>
              </div>
              <div className="courseDescription">
                <h4>About this course</h4>
                <p>{course?.description}</p>
              </div>
              <SingleButton>{Auth.loggedIn() && <BuyButton />}</SingleButton>
            </SingleCourseInfo>
          </SingleTop>
          <SingleBottom></SingleBottom>
        </SingleCardContainer>
      </SingleCourseContainer>
    </AuthCheckUser>
  );
};

export default SingleCourse;
