import {
  CourseHomepageContainer,
  BreadcrumbsContainer,
  CourseHomeRight,
  LinksContainer,
  SubsectionContainer,
  CouseHomeLeft,
  PresentationContainer,
  EachSection,
} from '../../TakeCourse/CourseHome.styles';
import { httpGetSingleCourse } from '../../Hooks/courses.hooks';
import { useParams, Link } from 'react-router-dom';
import BreadCrumbs from '../../Components/Breadcrumbs/BreadcrumbsComponent.component';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { AuthCheckUser } from '../../Utils/AuthCheck/UserCheck.auth';
import PlayIcon from '../../Visuals/Icons/PlayIcon.icon';
import LinkIcon from '../../Visuals/Icons/LinkIcon.icon';
import PresentationIcon from '../../Visuals/Icons/PresentationIcon.icon';
import Loader from '../../Components/Loader/LoaderComponent.component';
import { Helmet } from 'react-helmet';

// Page for reviewing the course
const CourseReview = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useQuery({
    queryKey: ['userId', id],
    queryFn: () => httpGetSingleCourse(id),
    enabled: !!id,
    retry: 5,
  });

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <h3>An error has occured</h3>;
  }

  return (
    <AuthCheckUser>
      <Helmet>
        <title>Review Course | Yoda Safety Services</title>
        <meta
          name="Review Course"
          content="Review the course but cannot take the quiz again"
        />
      </Helmet>
      <CourseHomepageContainer>
        <CourseHomeRight>
          <LinksContainer>
            <BreadcrumbsContainer>
              <BreadCrumbs one="Home" oneLink="/user" two={data?.courseName} />
            </BreadcrumbsContainer>
            <Link to="#presentation">Presentation</Link>
            <Link to="#documentation">Documentation</Link>
            <Link to="#videos">Video Links</Link>
            <span className="courseStatus">Course has been completed</span>
          </LinksContainer>
        </CourseHomeRight>
        <CouseHomeLeft>
          {!data ? (
            <>
              <p>{error}</p>
            </>
          ) : (
            <>
              <h1>{data?.courseName}</h1>
              <p>{data?.description}</p>
              <EachSection>
                <SubsectionContainer id="presentation">
                  <PresentationIcon />
                  <h2>Presentation</h2>
                </SubsectionContainer>
                {/* <p>Course:{course?.pdf?.publicId}</p> */}
                <PresentationContainer>
                  <iframe
                    src={data?.pdf}
                    title="presentation"
                    width="100%"
                    height="100%"
                  />
                </PresentationContainer>
              </EachSection>
              <EachSection>
                <SubsectionContainer id="documentation">
                  <LinkIcon />
                  <h2>Documentation</h2>
                </SubsectionContainer>
                <ul>
                  {/* The as may need to be change to Link */}
                  {data?.documentation?.map((l) => {
                    return (
                      <li key={l}>
                        <a
                          href={l}
                          target="_blank"
                          rel="noreferrer"
                          alt={'Documentation link to' + l}
                        >
                          {l}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </EachSection>
              <EachSection>
                <SubsectionContainer id="videos">
                  <PlayIcon />
                  <h2>Videos</h2>
                </SubsectionContainer>
                <ul>
                  {data?.videoLinks?.map((l) => {
                    return (
                      <li key={l}>
                        <a
                          href={l}
                          onChange={(e) => e.target}
                          target="_blank"
                          rel="noreferrer"
                          alt={'Video link to ' + l}
                        >
                          {l}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </EachSection>
            </>
          )}
        </CouseHomeLeft>
      </CourseHomepageContainer>
    </AuthCheckUser>
  );
};

export default CourseReview;
