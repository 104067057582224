import { useQuery } from '@tanstack/react-query';
import { httpGetUser } from '../../Hooks/users.hooks';
import UserRouterComponent from './UserRouterComponent.component';
import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../Components/Loader/LoaderComponent.component';

// Gets the users courses and sends them to the router component
const UserCourses = ({ user, chosen }) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ['userCourses'],
    queryFn: () => httpGetUser(user?.id),
    retry: 5,
  });

  if (error) {
    return <h3>An error occured getting the courses</h3>;
  }

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <UserRouterComponent
        courses={data?.courses}
        user={user}
        chosen={chosen}
      />
    </>
  );
};
UserCourses.propTypes = {
  user: PropTypes.object.isRequired,
  chosen: PropTypes.string.isRequired,
};

export default UserCourses;
