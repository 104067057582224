import CompletedCourse from '../UserCourses/CompleteCourse.component';
import IncompleteCourse from '../UserCourses/IncompleteCourse.component';
import AllCourses from '../UserCourses/AllCourses.component';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// prettier-ignore
const UserRouterComponent = ({ courses, user, chosen }) => {
  const [chosenItem, setChosenItem] = useState(
    <AllCourses courses={courses} data={user} />
  );
  const [incompleteCourses, setIncompleteCourses] = useState([]);
  const [completeCourses, setCompleteCourses] = useState([]);

  useEffect(() => {
    const incomplete = [];
    const complete = [];

    courses?.forEach(course => {
      if (course?.complete === false) {
        incomplete.push(course);
      } else {
        complete.push(course);
      }
    });

    setIncompleteCourses(incomplete);
    setCompleteCourses(complete);
  }, [courses]);

  useEffect(() => {
    if (chosen === 'AllCourses') {
      setChosenItem(<AllCourses courses={courses} data={user} />);
    } else if (chosen === 'IncompleteCourses') {
      setChosenItem(
        <IncompleteCourse courses={incompleteCourses} data={user} />
      );
    } else if (chosen === 'CompleteCourses') {
      setChosenItem(<CompletedCourse courses={completeCourses} data={user} />);
    } else {
      setChosenItem(<AllCourses courses={courses} data={user} />);
    }
  }, [chosen, user, courses]);

  return <>{chosenItem}</>;
};
UserRouterComponent.propTypes = {
  courses: PropTypes.object,
  user: PropTypes.object.isRequired,
  chosen: PropTypes.string.isRequired,
};

export default UserRouterComponent;
