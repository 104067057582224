import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { QuizContainer, ButtonContainer } from './Quiz.styles';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import InputTextNested from '../InputTextNested';

// The question that can be edited
const EditQuestion = ({
  editQuestion,
  questions,
  setQuestions,
  setEditQuestion,
  setCreateOrEdit,
}) => {
  const [quest, setQuest] = useState([]);
  const [quest2, setQuest2] = useState([]);
  const [option, setOption] = useState(true);
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setEditQuestion([]);
    setCreateOrEdit(true);

    let idx = questions.findIndex((e) => {
      if (e.id === editQuestion.id) {
        return e;
      }
      return null;
    });
    let que = [...questions];
    if (quest.id) {
      let q = {
        ...questions[idx],
        id: quest.id,
        question: quest.question,
        answer1: quest.answer1,
        answer2: quest.answer2,
        answer3: quest.answer3,
        answer4: quest.answer4,
        correctAnswer: quest.correctAnswer,
      };
      que[idx] = q;
      setQuestions(que);
    } else if (quest2.id) {
      let q = {
        ...questions[idx],
        id: quest2.id,
        question: quest2.question,
        answer1: quest2.answer1,
        answer2: quest2.answer2,
        correctAnswer: quest2.correctAnswer,
      };
      que[idx] = q;
      setQuestions(que);
    } else {
      setError('Cannot edit the question');
    }
  };

  useEffect(() => {
    let found = questions.filter((q) => q.id === editQuestion.id);
    if (found[0].answer3) {
      setOption(true);
      setQuest(found[0]);
    } else {
      setOption(false);
      setQuest2(found[0]);
    }
  }, [editQuestion, questions, option]);

  return (
    <QuizContainer>
      {option ? (
        <>
          <InputTextNested
            className="item"
            title="Question"
            value={quest?.question}
            name="question"
            nestedValue={setQuest}
            upperValue={quest}
          />
          <p className="error">{error}</p>
          <InputTextNested
            className="list"
            title="Answer 1"
            value={quest?.answer1}
            name="answer1"
            nestedValue={setQuest}
            upperValue={quest}
          />
          <InputTextNested
            className="list"
            title="Answer 2"
            value={quest?.answer2}
            name="answer2"
            nestedValue={setQuest}
            upperValue={quest}
          />
          <InputTextNested
            className="list"
            title="Answer 3"
            value={quest?.answer3}
            name="answer3"
            nestedValue={setQuest}
            upperValue={quest}
          />
          <InputTextNested
            className="list"
            title="Answer 4"
            value={quest?.answer4}
            name="answer4"
            nestedValue={setQuest}
            upperValue={quest}
          />
          <select
            multiple={false}
            // defaultValue={quest?.answer1}
            value={quest?.correctAnswer || ''}
            name="correctAnswer"
            onChange={(e) =>
              setQuest({ ...quest, [e.target.name]: e.target.value })
            }
          >
            <option value="">Select an answer</option>
            <option value={quest?.answer1}>Answer 1</option>
            <option value={quest?.answer2}>Answer 2</option>
            <option value={quest?.answer3}>Answer 3</option>
            <option value={quest?.answer4}>Answer 4</option>
          </select>
        </>
      ) : (
        <>
          <InputTextNested
            className="item"
            title="Question"
            value={quest2?.question}
            name="question"
            nestedValue={setQuest2}
            upperValue={quest2}
          />
          {error && <p className="error">{error}</p>}
          <p className="list">1. True</p>
          <p className="list">2. False</p>
          <div style={{ display: 'flex', gap: '3rem' }}>
            <p style={{ fontSize: '1.5rem' }}>
              Correct Answer: {quest2?.correctAnswer}
            </p>
            <select
              multiple={false}
              // defaultValue="true"
              value={quest2?.correctAnswer || ''}
              name="correctAnswer"
              onChange={(e) =>
                setQuest2({ ...quest2, [e.target.name]: e.target.value })
              }
            >
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
        </>
      )}
      <ButtonContainer>
        {/* <button className="secondaryButton boxShadow" onClick={(e)=> {
            e.preventDefault();
            setOption(!option);
            
        }}><span>Select Type</span></button> */}
        <PrimaryButton functionName={handleSubmit} span="Edit Question" />
      </ButtonContainer>
    </QuizContainer>
  );
};
EditQuestion.propTypes = {
  editQuestion: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired,
  setEditQuestion: PropTypes.func.isRequired,
  setCreateOrEdit: PropTypes.func.isRequired,
};

export default EditQuestion;
