import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import React, { useState } from 'react';
import Loader from '../../../Components/Loader/LoaderComponent.component';
import PropTypes from 'prop-types';
import { editCompany } from '../../../Utils/Companies/editCompany/editCompany';
import { httpCompanyLogo } from '../../../Hooks/admin.hooks';

// Function for editing a company
const SubmitEditCompany = ({
  user = {},
  company = {},
  setSuccessState,
  setIsOpen,
  logo = '',
}) => {
  const [loading, setLoading] = useState(false);

  const editCompanySubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (logo.name) {
      const formData = new FormData();
      formData.append('logo', logo);
      formData.append('companyId', company._id);
      await httpCompanyLogo(formData);
    }
    await editCompany(
      company,
      setSuccessState,
      setIsOpen,
      setLoading,
      user,
      logo
    );
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <PrimaryButton functionName={editCompanySubmit} span="Edit Company" />
      )}
    </>
  );
};

SubmitEditCompany.propTypes = {
  user: PropTypes.object,
  company: PropTypes.object,
  setSuccessState: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  logo: PropTypes.string,
};

export default SubmitEditCompany;
