import styled from 'styled-components';

// prettier-ignore
export const OnlineCoursesContainer = styled.main`
  display: flex;
  flex-direction: column;
  /* width: 90%; */
  padding-inline: ${({ theme }) => theme.spacing.xxl};
  background-color: ${({ theme }) => theme.colors.white};
  @media screen and (width <= 600px) {
    min-width: 100%;
    padding-inline: ${({ theme }) => theme.spacing.xs};
  }
  /* border: 1px solid red; */
`;

export const SearchContainer = styled.aside`
  width: 100%;
  margin-block: ${({ theme }) => theme.spacing.m};
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid blue; */
  input {
    margin-right: ${({ theme }) => theme.spacing.m};
    width: 250px;
    height: 45px;
    padding: ${({ theme }) => theme.spacing.xs};
  }
  @media screen and (width <= 700px) {
    flex-direction: column;
    align-items: start;
    gap: ${({ theme }) => theme.spacing.xs};
  }
  @media screen and (width <= 600px) {
    padding-left: ${({ theme }) => theme.spacing.m};
  }
`;

export const SearchBarContainer = styled.div`
  button {
    margin-right: 1rem;
  }
  @media screen and (width <= 600px) {
    width: 100%;
  }
`;

export const FilterButton = styled.aside`
  visibility: hidden;
  display: none;
  /* border: 1px solid red; */
  @media screen and (width <= 1100px) {
    visibility: visible;
    display: flex;
  }
`;

export const TagContainer = styled.aside`
  width: 30%;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding-block: ${({ theme }) => theme.spacing.m};
  padding-inline: ${({ theme }) => theme.spacing.m};
  background-color: ${({ theme }) => theme.colors.grey};
  label {
    color: ${({ theme }) => theme.colors.grey5} !important;
    padding-bottom: ${({ theme }) => theme.spacing.xxs};
    /* text-transform: uppercase; */
    font-weight: 700;
  }
  /* border: 1px solid pink; */
  @media screen and (width <= 1100px) {
    display: none;
    width: 0%;
  }
`;

export const CourseContainer = styled.section`
  width: 70%;
  height: 80vh;
  overflow-y: scroll;
  /* border: 1px solid orange; */
  @media screen and (width <= 1100px) {
    width: 100%;
  }
  @media screen and (width <= 600px) {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 1rem;
    /* border: 1px solid blue; */
  }
`;

export const CourseCardComponent = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.offWhite};
  margin-bottom: ${({ theme }) => theme.spacing.m};
  margin-inline: ${({ theme }) => theme.spacing.m};
  box-shadow: ${({ theme }) => theme.shadows.shadow2};
  /* border: 1px solid yellow; */
  @media screen and (width <= 900px) {
    margin-inline: 0;
  }
  @media screen and (width <= 600px) {
    width: 100%;
  }
`;

export const CourseImage = styled.div`
  margin-right: ${({ theme }) => theme.spacing.m};
  max-width: 30%;
  /* border: 1px solid yellow; */
  img {
    max-width: 100%;
    min-height: 100%;
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
    border-top-left-radius: ${({ theme }) => theme.borderRadius};
  }
  @media screen and (width <= 900px) {
    margin-right: 0;
  }
  @media screen and (width <= 600px) {
    width: 0;
    img {
      display: none;
    }
  }
`;
export const CourseInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-wrap: normal;
  padding-top: ${({ theme }) => theme.spacing.m};
  padding-left: ${({ theme }) => theme.spacing.xs};
  /* border: 1px solid red; */
  border-radius: ${({ theme }) => theme.borderRadius};
  .topContainer {
    height: 70%;
  }
  .buttonContainer {
    height: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: ${({ theme }) => theme.spacing.xs};
  }
  .buttonContainer button {
    padding-right: ${({ theme }) => theme.spacing.m};
    margin: 0;
    text-align: right;
  }
  h4 {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.grey5};
    text-transform: uppercase;
    padding-bottom: ${({ theme }) => theme.spacing.xs};
  }
  p {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey4};
  }
  @media screen and (width <= 600px) {
    gap: ${({ theme }) => theme.spacing.xxs};
    padding-top: 0;
    padding: ${({ theme }) => theme.spacing.s};
    border-left: 10px solid ${({ theme }) => theme.colors.orange3};
  }
`;
