import TagSelect from './Tags';
import React from 'react';
import PropTypes from 'prop-types';
import DestructiveButton from '../../Buttons/DestructiveButton/DestructiveButton.component';
import TagIcon from '../../../Visuals/Icons/TagIcon.icon';
// Displays the current selected tages
const TagsSelectedContainer = ({ tags, setTags }) => {
  return (
    <>
      <h4>
        Tags <TagIcon />
      </h4>
      <br />
      {tags?.map((t, idx) => {
        return (
          <div
            key={idx}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid lightGrey',
              padding: '0.5rem',
              marginInline: '2rem',
              marginBlock: '1rem',
            }}
          >
            <p
              value={t}
              style={{
                fontSize: '1.1rem',
                fontWeight: '600',
              }}
            >
              {idx + 1}. {t}
            </p>
            <DestructiveButton
              functionName={(e) => {
                e.preventDefault();
                let filtered = tags.filter((ti) => ti !== t);
                setTags(filtered);
              }}
              span="Delete Tag"
            />
          </div>
        );
      })}
      <TagSelect tags={tags} setTags={setTags} />
    </>
  );
};
TagsSelectedContainer.propTypes = {
  tags: PropTypes.array,
  setTags: PropTypes.func.isRequired,
};

export default TagsSelectedContainer;
