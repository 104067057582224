import { EachAnswer, EachQuestionContainer } from '../QuizPageStyles.styles';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Displays each question for the quiz
const EachQuestion = ({
  q,
  i,
  setCorrect,
  correct,
  setAnswered,
  setAnsweredQuestions,
  answeredQuestions,
  setUserAnswers,
}) => {
  const { id, question, correctAnswer, answer1, answer2, answer3, answer4 } = q;
  const [selected, setSelected] = useState();

  const checkAnswer = (e) => {
    const ans = e.target.value;
    if (ans === correctAnswer) {
      setCorrect(correct + 1);
    }
    setSelected(ans);
    setUserAnswers((prev) => ({
      ...prev,
      [id]: ans,
    }));
  };
  useEffect(() => {
    if (selected && !answeredQuestions[id]) {
      setAnswered((prev) => prev + 1);
      setAnsweredQuestions((prev) => ({
        ...prev,
        [id]: true,
      }));
    }
  }, [selected, id, answeredQuestions, setAnswered, setAnsweredQuestions]);

  return (
    <EachQuestionContainer>
      <div key={id} id={id}>
        <h3>
          {i + 1}. {question}
        </h3>
        {answer1 && (
          <EachAnswer className={selected === answer1 ? 'selectedAnswer' : ''}>
            <input
              type="radio"
              id={question}
              name="answer"
              value={answer1}
              onClick={checkAnswer}
            />
            <label htmlFor={question}>{answer1}</label>
          </EachAnswer>
        )}
        {answer2 && (
          <EachAnswer className={selected === answer2 ? 'selectedAnswer' : ''}>
            <input
              type="radio"
              id={id}
              name="answer"
              value={answer2}
              onClick={checkAnswer}
            />
            <label htmlFor={id}>{answer2}</label>
          </EachAnswer>
        )}
        {answer3 && (
          <EachAnswer className={selected === answer3 ? 'selectedAnswer' : ''}>
            <input
              type="radio"
              id={id}
              name="answer"
              value={answer3}
              onClick={checkAnswer}
            />
            <label htmlFor={id}>{answer3}</label>
          </EachAnswer>
        )}
        {answer4 && (
          <EachAnswer className={selected === answer4 ? 'selectedAnswer' : ''}>
            <input
              type="radio"
              id={id}
              name="answer"
              value={answer4}
              onClick={checkAnswer}
            />
            <label htmlFor={question}>{answer4}</label>
          </EachAnswer>
        )}
      </div>
    </EachQuestionContainer>
  );
};
EachQuestion.propTypes = {
  q: PropTypes.shape({
    id: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    correctAnswer: PropTypes.string.isRequired,
    answer1: PropTypes.string,
    answer2: PropTypes.string,
    answer3: PropTypes.string,
    answer4: PropTypes.string,
  }).isRequired,
  i: PropTypes.number.isRequired,
  setCorrect: PropTypes.func.isRequired,
  correct: PropTypes.number.isRequired,
  setAnswered: PropTypes.func.isRequired,
  setAnsweredQuestions: PropTypes.func.isRequired,
  answeredQuestions: PropTypes.object.isRequired,
  setUserAnswers: PropTypes.func.isRequired,
};

export default EachQuestion;
