import SecondaryButton from '../../../Components/Buttons/SecondaryButton/SecondaryButton.component';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../Components/Loader/LoaderComponent.component';
import { activateCompany } from '../../../Utils/Companies/editCompany/activateCompany';

// Activates a company
const ActivateCompanyButton = ({ id = '', setSuccessState, setIsOpen }) => {
  const [loading, setLoading] = useState(false);

  const activateCompanySubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!id) {
      setSuccessState('ID is required');
      setLoading(false);
      return;
    }
    await activateCompany(id, setSuccessState, setIsOpen, setLoading);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <SecondaryButton
          functionName={activateCompanySubmit}
          span="Activate Company"
        />
      )}
    </>
  );
};

ActivateCompanyButton.propTypes = {
  id: PropTypes.string,
  setSuccessState: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default ActivateCompanyButton;
