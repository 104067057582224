import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Auth from '../../Helpers/auth';

export const AuthCheckUser = ({ children }) => {
  const navigate = useNavigate();
  const { data } = Auth.getProfile();

  useEffect(() => {
    if (!Auth.loggedIn()) {
      navigate('/login');
    }
  }, [navigate, data]);

  return children;
};
