import React from 'react';
import { MainContainer } from '../MainRoute.styles';
import { Helmet } from 'react-helmet';
const TermsAndConditions = () => {
  return (
    <>
      <MainContainer>
        <Helmet>
          <title>Terms & Conditions | Yoda Safety Services</title>
          <meta
            name="Terms & Conditions"
            content="Terms and conditions for Yoda Safety Services"
          />
        </Helmet>
        <h2>Terms & Conditions</h2>
        <p>Last Changed: </p>
      </MainContainer>
    </>
  );
};

export default TermsAndConditions;
