import React from 'react';
import PropTypes from 'prop-types';
import { FileContainer } from '../../AdminRoutes/CreateRoutes/CreateForm.styles';
import PresentationIcon from '../../Visuals/Icons/PresentationIcon.icon';
import AsteriskIcon from '../../Visuals/Icons/AsteriskIcon.icon';

const PdfInput = ({ pdf, setNewPdf }) => {
  return (
    <>
      <h4>
        PDF <PresentationIcon />
      </h4>
      {/* <span>The current pdfs link is: {pdf}</span> */}
      <FileContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <label>
            Presentation Slideshow <AsteriskIcon />{' '}
            <span className="detail"> PDF Only</span>
            <br />
            {pdf && <span style={{ color: 'green' }}>{pdf.name}</span>}
          </label>
          <input
            type="file"
            required
            accept="application/pdf"
            onChange={(e) => setNewPdf(e.target.files[0])}
          />
        </div>

        {/* <div style={{ width: '50%' }}>
          {!pdf && (
            <iframe
              src={pdf}
              title="Presentation Slideshow"
              width="100%"
              height="300px"
            ></iframe>
          )}
        </div> */}
      </FileContainer>
    </>
  );
};
PdfInput.propTypes = {
  pdf: PropTypes.string,
  setNewPdf: PropTypes.func.isRequired,
};

export default PdfInput;
