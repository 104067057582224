import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { useParams } from 'react-router-dom';
import Loader from '../../../Components/Loader/LoaderComponent.component';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { editCourse } from '../../../Utils/Courses/editCourse/editCourse';
// Function for editing the course
const EditCourseSubmit = ({
  questions = [],
  pdf = '',
  notes = '',
  videoLinks = [],
  documentation = [],
  image = '',
  expiryDate = 0,
  certificatePrefix = 0,
  courseName = '',
  tags = [],
  description = '',
  price = 0,
  setSuccessState,
  setIsOpen,
}) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const fullCourse = {
      courseName: courseName,
      price: price,
      description: description,
      tags: tags,
      notes: notes,
      videoLinks: videoLinks,
      documentation: documentation,
      quiz: questions,
      expiryDate: expiryDate,
      certificatePrefix: certificatePrefix,
    };
    await editCourse(
      id,
      fullCourse,
      image,
      pdf,
      setSuccessState,
      setIsOpen,
      setLoading
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <PrimaryButton functionName={handleSubmit} span="Edit Course" />
      )}
    </>
  );
};
EditCourseSubmit.propTypes = {
  questions: PropTypes.array,
  pdf: PropTypes.string,
  notes: PropTypes.string,
  videoLinks: PropTypes.array,
  documentation: PropTypes.array,
  image: PropTypes.string,
  expiryDate: PropTypes.number,
  certificatePrefix: PropTypes.string,
  courseName: PropTypes.string,
  tags: PropTypes.array,
  description: PropTypes.string,
  price: PropTypes.number,
  setSuccessState: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default EditCourseSubmit;
