import PropTypes from 'prop-types';
import React from 'react';
import CompanyLogo from '../../../../Components/Form/CompanyLogo';
import InputTextNested from '../../../../Components/Form/InputTextNested';
import TextAreaNested from '../../../../Components/Form/TextAreaNexted';
import { DateAndTime } from '../../../CreateRoutes/CreateForm.styles';
import { format } from 'date-fns';

const EditCompanyInputs = ({ company = {}, setCompany, setLogo }) => {
  const formattedCreatedAt = company?.createdAt
    ? format(new Date(company.createdAt), 'MMMM dd yyyy,  HH:mm')
    : '';
  const formattedUpdatedAt = company?.updatedAt
    ? format(new Date(company.updatedAt), 'MMMM dd yyyy,  HH:mm')
    : '';
  return (
    <>
      <InputTextNested
        title="Company Name"
        value={company?.companyName}
        name="companyName"
        nestedValue={setCompany}
        upperValue={company}
        requiredInput={true}
        containerClassName="formInputUnderline"
      />
      <InputTextNested
        title="Main Contact"
        value={company?.mainContact}
        name="mainContact"
        nestedValue={setCompany}
        upperValue={company}
        requiredInput={true}
        containerClassName="formInputUnderline"
      />
      <InputTextNested
        title="Secondary Contact"
        value={company?.secondaryContact}
        name="secondaryContact"
        nestedValue={setCompany}
        upperValue={company}
        containerClassName="formInputUnderline"
      />
      <InputTextNested
        title="Office Phone Number"
        value={company?.phoneOffice}
        name="phoneOffice"
        nestedValue={setCompany}
        upperValue={company}
        requiredInput={true}
        containerClassName="formInputUnderline"
      />
      <InputTextNested
        title="Mobile Phone Number"
        value={company?.phoneMobile}
        name="phoneMobile"
        nestedValue={setCompany}
        upperValue={company}
        containerClassName="formInputUnderline"
      />
      <InputTextNested
        title="Website"
        value={company?.website}
        name="website"
        nestedValue={setCompany}
        upperValue={company}
        containerClassName="formInputUnderline"
      />
      <InputTextNested
        title="Address"
        value={company?.address}
        name="address"
        nestedValue={setCompany}
        upperValue={company}
        containerClassName="formInputUnderline"
      />
      <TextAreaNested
        title="Notes"
        value={company?.notes}
        name="notes"
        nestedValue={setCompany}
        upperValue={company}
        containerClassName="formInputUnderline"
      />
      <CompanyLogo
        company={company}
        setLogo={setLogo}
        containerClassName="formInputUnderline"
      />
      <DateAndTime>
        <p>
          Created At:
          <span> {formattedCreatedAt} </span>
        </p>
        <p>
          Updated At:
          <span> {formattedUpdatedAt} </span>
        </p>
      </DateAndTime>
    </>
  );
};
EditCompanyInputs.propTypes = {
  company: PropTypes.object,
  setCompany: PropTypes.func.isRequired,
  setLogo: PropTypes.func.isRequired,
};

export default EditCompanyInputs;
