import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 20;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  // border: 3px solid red;
  background-color: hsla(0, 0%, 45.88235294117647%, 0.5);
  .modal {
    background-color: ${({ theme }) => theme.colors.white};
    width: 60%;
    border-radius: ${({ theme }) => theme.borderRadius};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xl};
    position: absolute;
    border: none;
    top: 20%;
    height: 50vh;
    overflow-y: scroll;
    // border: 2px solid red;
  }
  .content {
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing.m};
    width: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  h6 {
    text-align: center;
  }
  button {
    min-width: 150px;
  }
  .topClose {
    height: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-top: ${({ theme }) => theme.spacing.m};
    padding-right: ${({ theme }) => theme.spacing.xl};
  }
  /* ----------------------------------------------
 * Generated by Animista on 2024-5-6 12:24:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  @-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }
`;
