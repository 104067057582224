import React from 'react';
import PropTypes from 'prop-types';
import OfficeIcon from '../../Visuals/Icons/OfficeIcon.icon';
const ImageInput = ({ image, setNewImage }) => {
  return (
    <>
      <h4>
        Thumbnail Picture <OfficeIcon />
      </h4>
      <span>The current picture is displayed</span>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBlock: '1rem',
          paddingBottom: '1rem',
          borderBottom: '1px solid #e6e6e6',
        }}
      >
        <img src={image} alt={`Thumbnail`} />
        <div style={{ display: 'flex', height: '40%' }}>
          <label>Change Thumbnail</label>
          <input
            type="file"
            accept="image/png, image/jpg"
            onChange={(e) => setNewImage(e.target.files[0])}
          />
        </div>
      </div>
    </>
  );
};
ImageInput.propTypes = {
  image: PropTypes.string.isRequired,
  setNewImage: PropTypes.func.isRequired,
};

export default ImageInput;
