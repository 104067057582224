import React from 'react';
import PropTypes from 'prop-types';
import { InputContainer } from './FormElements.styles';
import AsteriskIcon from '../../Visuals/Icons/AsteriskIcon.icon';

const InputTextNested = ({
  title,
  placeholder = '',
  value = '',
  nestedValue,
  upperValue,
  name,
  className = '',
  containerClassName = '',
  detail = '',
  requiredInput = false,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    nestedValue({ ...upperValue, [name]: value });
  };

  return (
    <InputContainer className={containerClassName}>
      <label className={className}>
        {title} <span className="detail">{detail}</span>
        {requiredInput && <AsteriskIcon />}
      </label>
      <input
        type="text"
        name={name}
        onChange={handleChange}
        value={value}
        required={requiredInput}
        placeholder={placeholder}
      />
    </InputContainer>
  );
};

InputTextNested.propTypes = {
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  nestedValue: PropTypes.func.isRequired,
  upperValue: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  detail: PropTypes.string,
  requiredInput: PropTypes.bool,
};

export default InputTextNested;
