import React from 'react';
import PropTypes from 'prop-types';
import DestructiveButton from '../../Buttons/DestructiveButton/DestructiveButton.component';

// Function for deleting a quesiton from the quiz
const DeleteQuestion = ({ questions, setQuestions, quest }) => {
  const clickedDelete = (e) => {
    e.preventDefault();
    let filtered = questions.filter((question) => question.id !== quest.id);
    setQuestions(filtered);
  };
  return (
    <DestructiveButton functionName={clickedDelete} span="Delete Question" />
  );
};
DeleteQuestion.propTypes = {
  questions: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired,
  quest: PropTypes.object.isRequired,
};

export default DeleteQuestion;
