import React from 'react';
import PropTypes from 'prop-types';
import { FileContainer } from '../../AdminRoutes/CreateRoutes/CreateForm.styles';
import OfficeIcon from '../../Visuals/Icons/OfficeIcon.icon';

// image took it out of the props
const LogoInput = ({ title = 'Logo', setImage, image }) => {
  return (
    <>
      <h4>
        Thumbnail Picture <OfficeIcon />
      </h4>
      <FileContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBlock: '1rem',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <label>
            {title}
            {image && <span style={{ color: 'green' }}>{image.name}</span>}
          </label>
          <input
            type="file"
            accept="image/png, image/jpg"
            onChange={(e) => setImage(e.target.files[0])}
          />
        </div>
      </FileContainer>
    </>
  );
};
LogoInput.propTypes = {
  title: PropTypes.string,
  setImage: PropTypes.func.isRequired,
  image: PropTypes.string,
};

export default LogoInput;
