import { httpCreateCourse, httpImgAndPdf } from '../../../Hooks/admin.hooks';

export const createCourse = async (
  course,
  formData,
  setSuccessState,
  setIsOpen,
  setLoading
) => {
  try {
    const res = await httpCreateCourse(course);
    const data = await res.json();
    if (res.ok) {
      const response = await httpImgAndPdf(formData);
      if (response.ok) {
        setSuccessState('Course has been created');
      } else {
        setSuccessState('An issue occured with uploading pdfs and images');
      }
    } else {
      setSuccessState(data.msg);
    }
  } catch (err) {
    setSuccessState('An error has occured creating the course', err.msg);
  } finally {
    setLoading(false);
    setIsOpen(true);
  }
};
