import { httpCreateManagerOrUser } from '../../../../Hooks/companies.hooks';

export const createManagerOrUser = async (
  fullUser,
  id,
  setResult,
  setLoading
) => {
  try {
    const response = await httpCreateManagerOrUser(fullUser, id);
    const data = await response.json();
    setResult(data.msg);
  } catch (err) {
    setResult(err.msg);
  } finally {
    setResult('An error has occured');
    setLoading(false);
  }
};
