import React from 'react';

const CreditCardIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="icon icon-credit-card"
      >
        <path
          className="primary"
          d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm13 12a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2h-2zm-6 0a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2h-2z"
        />
        <rect width="20" height="4" x="2" y="7" className="secondary" />
      </svg>
    </>
  );
};

export default CreditCardIcon;
