import {
  UserSettingsMain,
  UserSettingsSection,
  InputSection,
  TitleSection,
  BottomButtonOuter,
} from './UserSettingsStyles.styles';
import { ButtonContainer } from '../../Quiz/QuizPageStyles.styles';
import { useNavigate } from 'react-router-dom';
import Auth from '../../Helpers/auth';
import React, { useState } from 'react';
import PrimaryButton from '../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import SecondaryButton from '../../Components/Buttons/SecondaryButton/SecondaryButton.component';
import { httpDeleteUser, httpEditUser } from '../../Hooks/admin.hooks';
import { AuthCheckUser } from '../../Utils/AuthCheck/UserCheck.auth';
import DestructiveButton from '../../Components/Buttons/DestructiveButton/DestructiveButton.component';
import InputTextNested from '../../Components/Form/InputTextNested';
import { Helmet } from 'react-helmet';
import BreadCrumbs from '../../Components/Breadcrumbs/BreadcrumbsComponent.component';

/// User settings page
const UserSettings = () => {
  const navigate = useNavigate();
  const { data } = Auth.getProfile();
  const [user, setUser] = useState(data);
  const [err, setErr] = useState('');

  const deleteUser = async (e) => {
    e.preventDefault();
    let res = await httpDeleteUser(user?.id);
    let data = await res.json();
    if (res.ok) {
      setErr('Your account has been deleted, you will now be logged out');
      setTimeout(() => {
        Auth.logout();
        navigate('/');
      }, 8000);
    } else {
      setErr(data.msg);
    }
  };

  return (
    <AuthCheckUser>
      <Helmet>
        <title>Settings | Yoda Safety Services</title>
        <meta name="User settings" content="Update your profile." />
      </Helmet>
      <UserSettingsMain>
        <BreadCrumbs one="Home" oneLink={`/user`} two="Quiz" />
        <h2>Update your profile</h2>
        <form>
          <UserSettingsSection>
            {/* For first name and last name */}
            <TitleSection>
              <h4>Name</h4>
              <span className="italicSpan">
                The name displayed here is what will appear as on your
                certificate
              </span>
            </TitleSection>
            <InputSection>
              <InputTextNested
                title="First Name"
                value={user.firstName}
                name="firstName"
                nestedValue={setUser}
                upperValue={user}
              />
              <InputTextNested
                title="Last Name"
                value={user.lastName}
                name="lastName"
                nestedValue={setUser}
                upperValue={user}
              />
            </InputSection>
          </UserSettingsSection>
          <UserSettingsSection>
            {/* For Email and password */}
            <TitleSection>
              <h4>Login Details</h4>
            </TitleSection>
            <InputSection>
              <InputTextNested
                title="Email"
                value={user.email}
                name="email"
                nestedValue={setUser}
                upperValue={user}
              />
              <InputTextNested
                title="Password"
                value={user.password}
                name="password"
                nestedValue={setUser}
                upperValue={user}
              />
            </InputSection>
          </UserSettingsSection>
          <UserSettingsSection>
            <TitleSection>
              <h4>Delete your account</h4>
              <span className="italicSpan">
                This action cannot be undone and is permanent
              </span>
            </TitleSection>
            <InputSection>
              <DestructiveButton
                functionName={deleteUser}
                span="Delete Account"
              />
            </InputSection>
            {/* For Deleting the account */}
          </UserSettingsSection>
          <p>{err}</p>
          <BottomButtonOuter>
            {/* For the bottom 2 button */}
            <ButtonContainer>
              <SecondaryButton
                functionName={(e) => {
                  e.preventDefault();
                  navigate(`/user`);
                }}
                span="Home"
              />
              <PrimaryButton
                functionName={async (e) => {
                  e.preventDefault();
                  let res = await httpEditUser(user?.id, user);
                  let data = await res.json();
                  if (res.ok) {
                    setErr(
                      'Your account has been updated, reflections of change may take a moment to show'
                    );
                  } else {
                    setErr(data.msg);
                  }
                }}
                span="Update Profile"
              />
            </ButtonContainer>
          </BottomButtonOuter>
        </form>
      </UserSettingsMain>
    </AuthCheckUser>
  );
};

export default UserSettings;
