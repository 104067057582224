import React from 'react';
import { MainContainer } from '../MainRoute.styles';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <>
      <MainContainer>
        <Helmet>
          <title>Privacy Policy | Yoda Safety Services</title>
          <meta
            name="Privacy Policy"
            content="Privacy Policy for Yoda Safety Services"
          />
        </Helmet>
        <h2>Privacy Policy</h2>
        <p>Last Changed: </p>
      </MainContainer>
    </>
  );
};

export default PrivacyPolicy;
