import styled from 'styled-components';

export const SecondaryButtonContainer = styled.button`
  min-width: 140px;
  width: auto;
  max-width: 300px;
  height: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border: 2px solid ${({ theme }) => theme.colors.grey};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 0.6rem 1.5rem;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  span {
    font-weight: 600 !important;
    /* font-size: 1rem !important; */
    letter-spacing: 0.1rem !important;
    text-transform: uppercase !important;
    font-style: normal !important;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.colors.grey4};
  }
  &:hover {
    background: ${({ theme }) => theme.colors.grey1};
    color: ${({ theme }) => theme.colors.grey5};
    border: 2px solid ${({ theme }) => theme.colors.grey5};
  }
`;
