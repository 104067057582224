import Auth from '../../Helpers/auth.jsx';
import { ButtonContainer } from '../Completed/QuizComplete.styles.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Certificate from '../Certificate/CertificateTemplate.component.jsx';
import { httpGetCertificateInfo } from '../../Hooks/users.hooks.js';
import SecondaryButton from '../../Components/Buttons/SecondaryButton/SecondaryButton.component.jsx';

const DisplayUser = ({ courseName }) => {
  const { data } = Auth.getProfile();
  const { id } = useParams();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    const FindInfo = async () => {
      let found = await httpGetCertificateInfo(data.id, id);
      setUserInfo(found);
    };
    FindInfo();
  }, [data.id, id]);

  return (
    <>
      <h1>Congrats! You have completed {courseName}</h1>
      <ButtonContainer>
        <SecondaryButton
          functionName={() => navigate('/user')}
          span="Go Home"
        />
        <Certificate
          name={data?.firstName + ' ' + data?.lastName}
          course={userInfo?.courses[0]?.courseName}
          dateCompleted={userInfo?.courses[0]?.date}
          expiryDate={userInfo?.courses[0]?.expiryDate}
          certNumber={userInfo?.courses[0]?.certNum}
        />
      </ButtonContainer>
    </>
  );
};
DisplayUser.propTypes = {
  courseName: PropTypes.string.isRequired,
};

export default DisplayUser;
