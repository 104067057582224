import { httpEditCourse, httpCreateFiles } from '../../../Hooks/admin.hooks';

export const editCourse = async (
  id,
  fullCourse,
  image,
  pdf,
  setSuccessState,
  setIsOpen,
  setLoading
) => {
  try {
    let res = await httpEditCourse(id, fullCourse);
    let data = await res.json();
    if (res.ok) {
      if (image || pdf) {
        const formData = new FormData();
        if (image) {
          formData.append('image', image);
        }
        if (pdf) {
          formData.append('pdf', pdf);
        }
        formData.append('courseName', data.courseName);
        let res = await httpCreateFiles(formData);
        let d = await res.json();
        if (res.ok) {
          setSuccessState('Course has been edited');
        } else {
          setSuccessState(d.msg);
        }
      }
      setSuccessState('Course has been edited');
    }
  } catch (err) {
    setSuccessState('An error has occured editing the course');
  } finally {
    setIsOpen(true);
    setLoading(false);
  }
};
