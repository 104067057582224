import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { quizPercentage } from '../Helpers/quiz-percentage';
import { addYears, format } from 'date-fns';
import Auth from '../Helpers/auth';
import React, { useState } from 'react';
import { httpCompleteCourse, httpIncompleteCourse } from '../Hooks/users.hooks';
import PrimaryButton from '../Components/Buttons/PrimaryButton/PrimaryButton.component';
import PropTypes from 'prop-types';
import { GetPurchaseDate } from './CoursePurchaseDate';

// Form for submitting the quiz
const QuizSubmit = ({
  id,
  correct,
  length,
  courseName,
  course,
  answered,
  userAnswers,
}) => {
  const navigate = useNavigate();
  const [err, setErr] = useState('');
  const { data } = Auth.getProfile();

  const submitQuiz = async (e, id) => {
    e.preventDefault();
    let isCompleted = await quizPercentage(correct, length);
    // The purchaseDate could one day be expanded to more things then use the ... operator to add it in
    let purchaseDate = await GetPurchaseDate(data.id, id);
    console.log('Submit Quiz', purchaseDate);
    if (isCompleted >= 60) {
      let date = Date.now();
      const dateFormatted = format(new Date(), 'MMMM dd, yyyy');
      let expiryDate = addYears(date, course?.expiryDate);
      let expired = format(expiryDate, 'MMMM dd, yyyy');
      let cert = String(course.certificateCount);
      let certificate = `${course.certificatePrefix}${cert.padStart(4, '0')}`;
      let updatedCourse = {
        id: uuidv4(),
        courseId: id,
        courseName,
        certNum: certificate,
        complete: true,
        score: isCompleted,
        date: dateFormatted,
        expiryDate: expired,
        userAnswers,
        purchaseDate,
      };
      const res = await httpCompleteCourse(updatedCourse, data.id);
      const da = await res.json();
      if (res.ok) {
        navigate(`/course/quiz/complete/${id}`);
      } else {
        setErr(da.msg);
      }
    } else {
      let updatedCourse = {
        courseName,
        userAnswers: userAnswers,
        complete: false,
        courseId: id,
        purchaseDate,
      };
      const res = await httpIncompleteCourse(updatedCourse, data.id);
      const da = await res.json();
      if (res.ok) {
        navigate(`/course/quiz/incomplete/${id}`);
      } else {
        setErr(da.msg);
      }
    }
  };

  return (
    <>
      {err && <p className="error">{err}</p>}
      <PrimaryButton
        functionName={(e) => submitQuiz(e, id)}
        span="Submit Quiz"
        disabled={answered === length ? false : true}
      />
    </>
  );
};
QuizSubmit.propTypes = {
  id: PropTypes.string.isRequired,
  correct: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  courseName: PropTypes.string.isRequired,
  answered: PropTypes.number.isRequired,
  userAnswers: PropTypes.object.isRequired,
  course: PropTypes.shape({
    certificateCount: PropTypes.number.isRequired,
    certificatePrefix: PropTypes.string.isRequired,
    expiryDate: PropTypes.number.isRequired,
  }).isRequired,
};

export default QuizSubmit;
