import styled from 'styled-components';

// prettier-ignore
export const AuthContainer = styled.main`
  display: flex;
  justify-content: center; 
  a {
    color: ${({ theme }) => theme.colors.orange5};
    text-decoration: underline;
    font-weight: 700;
    letter-spacing: 0;
    font-size: 1rem;
  }

`;

export const AuthContainer2 = styled.section`
  height: 80%;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (width <=1150px) {
    width: 95%;
    /* border: 1px solid ${({ theme }) => theme.colors.grey5}; */
  }
`;
export const PictureSide = styled.section`
  width: 40%;
  height: 90%;
  display: flex;
  box-shadow: ${({ theme }) => theme.shadows.shadow2};
  img {
    width: 100%;
    height: auto;
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
    border-top-left-radius: ${({ theme }) => theme.borderRadius};
  }
  @media screen and (width <=1150px) {
    display: none;
  }
`;

export const AuthenticationSide = styled.section`
  width: 60%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  border-top-right-radius: ${({ theme }) => theme.borderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.offWhite};
  box-shadow: ${({ theme }) => theme.shadows.shadow2};
  h2 {
    margin-bottom: ${({ theme }) => theme.spacing.l};
  }
  form {
    padding: ${({ theme }) => theme.spacing.s};
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  form button {
    min-width: 100%;
    margin-block: ${({ theme }) => theme.spacing.s};
  }
  @media screen and (width <=1150px) {
    width: 100%;
    height: 80%;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: none;
    /* border: 1px solid ${({ theme }) => theme.colors.grey5}; */
  }
`;

export const EachInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  //justify-content: center;
  //align-items: start;
  /* border: 1px solid ${({ theme }) => theme.colors.grey5}; */
  gap: ${({ theme }) => theme.spacing.xxs};
  margin-bottom: ${({ theme }) => theme.spacing.s};
  label {
    font-weight: bold;
    padding-right: ${({ theme }) => theme.spacing.xs};
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
  }
  input {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing.s};
  }
  span {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.grey3};
  }
  .passwordSwitch {
    color: ${({ theme }) => theme.colors.grey5};
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
  }
  .passwordSwitch:hover {
    color: ${({ theme }) => theme.colors.yellow4};
  }
  .passwordContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  @media screen and (width <= 800px) {
    .passwordContainer {
      flex-direction: column;
      align-items: start;
      gap: ${({ theme }) => theme.spacing.xxs};
    }
  }
`;

export const OptionsContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
`;

export const SwitchPage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  /* border: 1px solid red; */
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing.xs};
  margin-left: ${({ theme }) => theme.spacing.s};
  button {
    padding-left: 0;
  }
  @media screen and (width <= 800px) {
    flex-direction: column;
    padding-top: 0;
    /* margin-left: ${({ theme }) => theme.spacing.s}; */
    gap: 0;
    align-items: start;
  }
`;
