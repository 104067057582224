import Auth from '../Helpers/auth';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = Auth.getToken();

//GET: Gets all companies for admin page - Protected route
async function httpGetAllCompanies() {
  try {
    let companies = await fetch(`${API_URL}/admin/companies`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return companies.json();
  } catch (err) {
    return err;
  }
}

// GET: Gets all the users for admin page - protected route
async function httpGetAllUsers() {
  try {
    let users = await fetch(`${API_URL}/admin/users`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return users.json();
  } catch (err) {
    return err;
  }
}

// GET: Gets a single user - protected route
async function httpGetSingleUser(id) {
  try {
    let user = await fetch(`${API_URL}/admin/user/${id}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return user.json();
  } catch (err) {
    return err;
  }
}

//POST: Creates a course - protected route
async function httpCreateCourse(formData) {
  try {
    return await fetch(`${API_URL}/admin/create-course`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify(formData),
    });
  } catch (err) {
    return err;
  }
}

// POST: Creates a company - protected route
async function httpCreateCompany(company, admin) {
  try {
    return await fetch(`${API_URL}/admin/create-company`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify({ company, admin }),
    });
  } catch (err) {
    return err;
  }
}

// POST: Creates pdfs and images - protected route
async function httpCreateFiles(formData) {
  try {
    return await fetch(`${API_URL}/admin/create-files`, {
      method: 'post',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: formData,
    });
  } catch (err) {
    return err;
  }
}

// POST: Creates logo for company - protected route
async function httpCompanyLogo(formData) {
  console.log('ROUTE FORM DATA', formData);
  try {
    return await fetch(`${API_URL}/admin/create-logo`, {
      method: 'post',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: formData,
    });
  } catch (err) {
    return err;
  }
}

// POST: Creates a user from admin - protected route
async function httpCreateUser(user) {
  try {
    return await fetch(`${API_URL}/admin/create-user`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify(user),
    });
  } catch (err) {
    return err;
  }
}

async function httpImgAndPdf(formData) {
  try {
    return await fetch(`${API_URL}/admin/create-files`, {
      method: 'post',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: formData,
    });
  } catch (err) {
    return err;
  }
}

// PUT: Edits a course - protected route
async function httpEditCourse(courseId, course) {
  try {
    return await fetch(`${API_URL}/admin/edit-course/${courseId}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify(course),
    });
  } catch (err) {
    return err;
  }
}

// PUT: Edits a company - protected route
async function httpEditCompany(companyId, company) {
  try {
    return await fetch(`${API_URL}/admin/edit-company/${companyId}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify(company),
    });
  } catch (err) {
    return err;
  }
}

// PUT: Edits a user - protected route
async function httpEditUser(userId, user) {
  try {
    return await fetch(`${API_URL}/admin/edit-user/${userId}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify(user),
    });
  } catch (err) {
    return err;
  }
}

// DELETE: Deletes a course by id - protected route
async function httpDeleteCourse(id) {
  try {
    return await fetch(`${API_URL}/admin/delete-course/${id}`, {
      method: 'delete',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  } catch (err) {
    return err;
  }
}

// DELETE: Deletes a user by id - protected route
async function httpDeleteUser(id) {
  try {
    return await fetch(`${API_URL}/admin/delete-user/${id}`, {
      method: 'delete',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  } catch (err) {
    return err;
  }
}

// DELETE: Deletes a company by id - protected route
async function httpDeleteCompany(id) {
  try {
    return await fetch(`${API_URL}/admin/delete-company/${id}`, {
      method: 'delete',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  } catch (err) {
    return err;
  }
}

export {
  httpGetAllCompanies,
  httpGetAllUsers,
  httpGetSingleUser,
  httpCreateCompany,
  httpCreateCourse,
  httpCreateUser,
  httpCreateFiles,
  httpCompanyLogo,
  httpEditCompany,
  httpImgAndPdf,
  httpEditCourse,
  httpEditUser,
  httpDeleteCourse,
  httpDeleteUser,
  httpDeleteCompany,
};
