import styled from 'styled-components';

export const FooterDiv = styled.footer`
  background-color: black;
  //position: absolute;
  padding-block: ${({ theme }) => theme.spacing.m};
  width: 100%;
  margin-top: auto;
  overflow-x: hidden;
  z-index: -2;
`;

export const OuterDiv = styled.div`
  /* border: 1px solid orange; */
  display: flex;
  gap: ${({ theme }) => theme.spacing.m};
  @media screen and (width <= 1100px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const LeftFooter = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxs};
  padding-inline: ${({ theme }) => theme.spacing.m};
  img {
    height: 100px;
    width: 180px;
  }
  a {
    color: ${({ theme }) => theme.colors.yellow5};
    text-decoration: underline;
    padding-right: 1rem;
    font-size: 1rem;
  }
  p {
    color: ${({ theme }) => theme.colors.grey};
  }
  span {
    color: ${({ theme }) => theme.colors.grey1};
    font-style: italic;
  }
  @media screen and (width <= 800px) {
    img {
      height: 70px;
      width: 140px;
    }
    p,
    span {
      font-size: ${({ theme }) => theme.spacing.s};
    }
  }
`;
