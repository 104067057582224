import React from 'react';
import PropTypes from 'prop-types';
import { SecondaryButtonContainer } from './SecondaryButton.styles';
// Secondary Button Component
const SecondaryButton = ({ functionName, span }) => {
  return (
    <SecondaryButtonContainer onClick={functionName}>
      <span>{span}</span>
    </SecondaryButtonContainer>
  );
};
SecondaryButton.propTypes = {
  functionName: PropTypes.func.isRequired,
  span: PropTypes.string.isRequired,
};

export default SecondaryButton;
