import SecondaryButton from '../../../Components/Buttons/SecondaryButton/SecondaryButton.component';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../Components/Loader/LoaderComponent.component';
import { disableCompany } from '../../../Utils/Companies/editCompany/deactivateCompany';

// Deactivates a company
const DeactivateCompanyButton = ({ id = '', setSuccessState, setIsOpen }) => {
  const [loading, setLoading] = useState(false);

  const editCompany = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!id) {
      setSuccessState('ID is required');
      setLoading(false);
      setIsOpen(true);
      return;
    }
    await disableCompany(id, setIsOpen, setSuccessState, setLoading);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <SecondaryButton functionName={editCompany} span="Deactivate Company" />
      )}
    </>
  );
};
DeactivateCompanyButton.propTypes = {
  id: PropTypes.string,
  setSuccessState: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default DeactivateCompanyButton;
