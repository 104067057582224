import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryButton from '../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { httpResetPassword } from '../../Hooks/users.hooks';
import PasswordInput from '../../Components/Form/PasswordInput';
import Loader from '../../Components/Loader/LoaderComponent.component';
import { Helmet } from 'react-helmet';
import { PasswordOuterContianer } from './ForgotAndReset.styles';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [pass, setPass] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [verifyPass, setVerifyPass] = useState('');
  const [verifyShowPass, setVerifyShowPass] = useState(false);
  const [err, setErr] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChangePassword = (e) => {
    setPass(e.target.value);
  };
  const handleChangeVerifyPassword = (e) => {
    setVerifyPass(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess('');
    setErr('');
    setLoading(true);
    if (pass !== verifyPass) {
      setErr('Passwords do not match');
      setLoading(false);
      return;
    }
    try {
      const res = await httpResetPassword(token, pass);
      const data = res.json();
      if (res.ok) {
        setSuccess('Password has been reset');
        setLoading(false);
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      } else {
        setErr(data.msg);
        setLoading(false);
      }
    } catch (err) {
      setErr('An error has occured');
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Reset Password | Yoda Safety Services</title>
        <meta
          name="Reset Password"
          content="Reset your password for your account"
        />
      </Helmet>
      <PasswordOuterContianer>
        <h2>Reset Password</h2>
        <form>
          {/* Password */}
          <PasswordInput
            label="Password"
            span="Must be at least 6 characters"
            handleChange={(e) => {
              e.preventDefault();
              setShowPass((prev) => !prev);
            }}
            showPass={showPass}
            value={pass}
            name="pass"
            onChange={handleChangePassword}
          />
          {/* Verify Password */}
          <PasswordInput
            label="Verify Password"
            handleChange={(e) => {
              e.preventDefault();
              setVerifyShowPass((prev) => !prev);
            }}
            value={verifyPass}
            onChange={handleChangeVerifyPassword}
            name="verifyPass"
            showPass={verifyShowPass}
          />
          {err && <p style={{ color: 'red' }}>{err}</p>}
          {success && <p style={{ color: 'green' }}>{success}</p>}
          {loading ? (
            <Loader />
          ) : (
            <PrimaryButton functionName={handleSubmit} span="Reset Password" />
          )}
        </form>
      </PasswordOuterContianer>
    </>
  );
};

export default ResetPassword;
