import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateCourseSubmit from './CourseCreateSubmit';
import Modal from '../../../Components/Modal/ModalComponent.component';
// import ImageCrop from "../../ImageCrop/imgCrop.component";
import { FormContainer, SubmitContainer } from '../CreateForm.styles';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import ArrayInput from '../../../Components/Form/ArrayInput';
import TextareaDiv from '../../../Components/Form/Textarea';
import InputNumber from '../../../Components/Form/InputNumber';
import TagsSelectedContainer from '../../../Components/Form/TagElement/TagsContainer.component';
import QuestionContainerComponent from '../../../Components/Form/QuestionElements/QuestionContainer.component';
import { AuthCheckAdmin } from '../../../Utils/AuthCheck/AdminCheck.auth';
import PdfInput from '../../../Components/Form/PdfInput.component';
import LogoInput from '../../../Components/Form/LogoInput';
import InputText from '../../../Components/Form/InputText';
import { Helmet } from 'react-helmet';
import LinkIcon from '../../../Visuals/Icons/LinkIcon.icon';
import PlayIcon from '../../../Visuals/Icons/PlayIcon.icon';
// prettier-ignore
// Form for creating the course
const CreateCourse = () => {
  const navigate = useNavigate();

  const [courseName, setCourseName] = useState('');
  const [price, setPrice] = useState(25);
  const [description, setDescription] = useState('');
  const [expiryDate, setExpiryDate] = useState(0);
  const [certificatePrefix, setCertificatePrefix] = useState('');
  const [tags, setTags] = useState([]);
  const [image, setImage] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [successState, setSuccessState] = useState('');
  const [pdf, setPdf] = useState(null);
  const [video, setVideo] = useState('');
  const [videoLinks, setVideoLinks] = useState([]);
  const [doc, setDoc] = useState('');
  const [documentation, setDocumentation] = useState([]);
  const [notes, setNotes] = useState('');
  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
     const checkFormValidity = () => {
      const isCourseName = courseName.trim() !== '';
      const isDescription = description.trim() !== '';
      const isPrice = price > 0;
      const isExpiryDate = expiryDate > 0;
      const isCertificatePrefix = certificatePrefix.trim() !== '';
      const isImage = image !== null;
      const isQuestions = questions.length > 0;
      const isPdf = pdf !== null;
      setValidForm(isCourseName && isDescription && isPrice && isExpiryDate && isCertificatePrefix && isImage && isQuestions && isPdf); 
    };
    checkFormValidity();
  }, [courseName, description, price, expiryDate, certificatePrefix, image, questions, pdf]);

  return (
    <AuthCheckAdmin>
      <Helmet>
              <title>Create Course | Yoda Safety Services</title>
              <meta name="Create a course" content="Form for creating a course" />
            </Helmet>
      <FormContainer>
        <h2>CREATE COURSE</h2>
        <form>
          {/* Course Name */}
          <InputText
            value={courseName}
            name="courseName"
            title="Course Name"
            requiredInput={true}
            setValue={setCourseName}
            containerClassName="formInputUnderline"
          />
          {/* Description */}
          <TextareaDiv
            value={description}
            requiredInput={true}
            title="Course Description"
            setValue={setDescription}
            containerClassName="formInputUnderline"
          />
          {/* Price */}
          <InputNumber requiredInput={true} value={price} title="Course Price" setValue={setPrice} containerClassName="formInputUnderline"/>
          {/* Expiry Date */}
          <InputNumber
            value={expiryDate}
            requiredInput={true}
            title="Expiry Date"
            detail="in years"
            setValue={setExpiryDate}
            containerClassName="formInputUnderline"
          />
          {/* Certificate Prefix */}
          <InputText
            value={certificatePrefix}
            name="certificatePrefix"
            title="Certificate Prefix"
            detail=" format: FP-"
            requiredInput={true}
            setValue={setCertificatePrefix}
            placeholder="FP-"
            containerClassName="formInputUnderline"
          />
          {/* Notes about the course */}
          <TextareaDiv value={notes} title="Notes" setValue={setNotes} containerClassName="formInputUnderline"/>
          {/* Video Links */}
          <ArrayInput
            label="Video Links"
            single={video}
            arrayItems={videoLinks}
            setSingle={setVideo}
            setArray={setVideoLinks}
            icon={<PlayIcon />}
            // containerClassName="formInputUnderline"
          />
          {/* Documentation */}
          <ArrayInput
            label="Documentation"
            single={doc}
            arrayItems={documentation}
            setSingle={setDoc}
            setArray={setDocumentation}
            icon={<LinkIcon />}
            // containerClassName="formInputUnderline"
          />
          {/* PDF Upload */}
          <PdfInput pdf={pdf} setNewPdf={setPdf} containerClassName="formInputUnderline"/>
          {/* Thumbnail Upload */}
          <LogoInput title="Course Thumbnail" image={image} setImage={setImage} containerClassName="formInputUnderline"/>
          {/* Tags */}
          <TagsSelectedContainer tags={tags} setTags={setTags} containerClassName="formInputUnderline"/>
          {/* Quiz */}
          <QuestionContainerComponent
            questions={questions}
            setQuestions={setQuestions}
            containerClassName="formInputUnderline"
          />
          {isOpen && (
            <Modal onClose={() => setIsOpen(false)}>
              <p>{successState}</p>
              <PrimaryButton
                functionName={() => navigate('/admin')}
                span="Go Home"
              />
            </Modal>
          )}
          <SubmitContainer>
            <CreateCourseSubmit
              pdf={pdf}
              videoLinks={videoLinks}
              documentation={documentation}
              notes={notes}
              questions={questions}
              courseName={courseName}
              tags={tags}
              image={image}
              description={description}
              price={price}
              expiryDate={expiryDate}
              certificatePrefix={certificatePrefix}
              setIsOpen={setIsOpen}
              setSuccessState={setSuccessState}
              disabled={validForm}
              successState={successState}
            />
          </SubmitContainer>
        </form>
      </FormContainer>
    </AuthCheckAdmin>
  );
};

export default CreateCourse;
