import { useParams } from 'react-router-dom';
import { QuizCompleteContainer, ResultsContainer } from './QuizComplete.styles';
import React from 'react';
// import Auth from '../../Helpers/auth';
import { httpGetSingleCourse } from '../../Hooks/courses.hooks';
import DisplayUser from '../DisplayUserInfo/DisplayUserComponent.component';
import { useQuery } from '@tanstack/react-query';
import { AuthCheckUser } from '../../Utils/AuthCheck/UserCheck.auth';
import { Helmet } from 'react-helmet';
import FinishedPicture from '../../Visuals/Images/finished.svg';
import ReviewQuiz from '../ReviewQuiz/ReviewQuiz.component';
// Page for when course has been completed
const CourseComplete = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useQuery({
    queryKey: ['userId', id],
    queryFn: () => httpGetSingleCourse(id),
    enabled: !!id,
    retry: 5,
  });

  if (error) {
    return <h3>An error has occured</h3>;
  }
  if (isLoading) {
    return <h3>Loading...</h3>;
  }

  return (
    <AuthCheckUser>
      <Helmet>
        <title>Course Complete | Yoda Safety Services</title>
        <meta name="Course Complete" content="Completed the course" />
      </Helmet>
      <QuizCompleteContainer>
        <ResultsContainer>
          <img src={FinishedPicture} alt="hexagons" />
          <DisplayUser courseName={data?.courseName} />
        </ResultsContainer>
        {/* For Reviewing the Quiz */}
        <ReviewQuiz dataCourse={data} />
      </QuizCompleteContainer>
    </AuthCheckUser>
  );
};

export default CourseComplete;
