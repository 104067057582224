import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { FormContainer } from '../../CreateRoutes/CreateForm.styles';
import Modal from '../../../Components/Modal/ModalComponent.component';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { AuthCheckAdmin } from '../../../Utils/AuthCheck/AdminCheck.auth';
import EditCompanyInputs from './Form/EditInputsComponent.component';
import useCompany from '../../../Utils/Companies/singleCompany/useCompany';
import EditFormButtons from './Form/EditButtonsComponent.component';
import { Helmet } from 'react-helmet';

// Form for editing a company
const EditCompany = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [logo, setLogo] = useState('');
  const { company, successState, setSuccessState } = useCompany(id);
  const [companyState, setCompanyState] = useState(company || {});

  useEffect(() => {
    setCompanyState(company);
  }, [company]);

  const gotoManagers = () => {
    navigate(`/admin/edit-company/${id}/managers-employees`);
  };

  return (
    <AuthCheckAdmin>
      <Helmet>
        <title>Edit Company | Yoda Safety Services</title>
        <meta name="Edit a company" content="Edit a company form" />
      </Helmet>
      <FormContainer>
        <h2>Edit Company</h2>
        <form>
          {/* The inputs for the form */}
          <EditCompanyInputs
            company={companyState}
            setCompany={setCompanyState}
            setLogo={setLogo}
          />
          {/* Pop up Modal when the course has been submitted */}
          {isOpen && (
            <Modal onClose={() => setIsOpen(false)}>
              <p>{successState}</p>
              <PrimaryButton
                functionName={() => navigate('/admin')}
                span="Go Home"
              />
            </Modal>
          )}
          {/* Shows the buttons on the form */}
          <EditFormButtons
            company={companyState}
            gotoManagers={gotoManagers}
            setIsOpen={setIsOpen}
            setSuccessState={setSuccessState}
            logo={logo}
          />
        </form>
      </FormContainer>
    </AuthCheckAdmin>
  );
};

export default EditCompany;
