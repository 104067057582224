import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import Error from '../../Visuals/Images/Error.svg';
import { ErrorBoundaryContainer, ErrorWords } from './ErrorBoundary.styles';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <ErrorBoundaryContainer>
      <div>
        <img src={Error} alt="Error" />
        <ErrorWords>
          <p>Sorry, something went wrong:</p>
          <span>{error.message}</span>
          <button onClick={resetErrorBoundary}>Try again</button>
        </ErrorWords>
      </div>
    </ErrorBoundaryContainer>
  );
};

const ErrorBoundary = ({ children }) => {
  const navigate = useNavigate();
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        navigate('/');
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
};

ErrorFallback.propTypes = {
  error: PropTypes.object.isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
