import Certificate from '../../Quiz/Certificate/CertificateTemplate.component';
import {
  EachCourse,
  BottomCourseContainer,
  CourseStatusContainer,
} from './CourseEntity.styles';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import SecondaryButton from '../../Components/Buttons/SecondaryButton/SecondaryButton.component';

// prettier-ignore
// Entity on user home page for showing that the course is complete
const CompletedCourse = ({ courses, data }) => {
  const navigate = useNavigate();
  return (
    <div>
      {!courses?.length && 
       <>
                <CourseStatusContainer>
                  <h3>You have completed all your courses</h3>
                  <SecondaryButton
                    functionName={() => navigate('/online')}
                    span="Browse Courses"
                  />
                </CourseStatusContainer>
              </>}
      {courses?.map((c) => {
        if (c?.complete) {
          return (
            <EachCourse className="complete boxShadow" key={c.id}>
              <div className="top">
                <Link className="title" to={`/course/review/${c.courseId}`} title="Review Course">
                  {c?.courseName}
                </Link>
                <BottomCourseContainer>
              <div>
              <p>
                  Completed: <span>{c?.date}</span>
                </p>
                <p>
                  Expires: <span>{c?.expiryDate}</span>
                </p>
              </div>
              <div>
              <Certificate
                  key={c?._id}
                  name={data?.firstName + ' ' + data?.lastName}
                  course={c?.courseName}
                  dateCompleted={c?.date}
                  expiryDate={c?.expiryDate}
                  certNumber={c?.certNum}
                />
              </div>
              </BottomCourseContainer>
                </div>
            </EachCourse>
          );
        }
        return null;
      }
      )}
    </div>
  );
};
CompletedCourse.propTypes = {
  courses: PropTypes.array,
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

export default CompletedCourse;
