import React from 'react';
// import { AuthCheckManager } from '../../Utils/AuthCheck/ManagerCheck';
// Main Frame for the company home
import { Helmet } from 'react-helmet';
const CompanyHome = () => {
  return (
    // <AuthCheckManager>
    <>
      <Helmet>
        <title>Company Home | Yoda Safety Services</title>
        <meta name="Company Homepage" content="Companies homepage" />
      </Helmet>
      <h2>Company Home</h2>
    </>
    // {/* </AuthCheckManager> */}
  );
};

export default CompanyHome;
