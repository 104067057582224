import React, { useState } from 'react';
import Loader from '../../../Components/Loader/LoaderComponent.component';
import PropTypes from 'prop-types';
import { deleteUser } from '../../../Utils/Users/editUsers/deleteUser';
import DestructiveButton from '../../../Components/Buttons/DestructiveButton/DestructiveButton.component';

// Deletes a user
const DeleteUserButton = ({ setSuccessState, setIsOpen, id = '' }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async (e) => {
    e.preventDefault();
    if (!id) {
      setSuccessState('User ID is missing');
      setIsOpen(true);
      return;
    }
    setLoading(true);
    await deleteUser(id, setSuccessState, setIsOpen, setLoading);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <DestructiveButton functionName={handleDelete} span="Delete User" />
      )}
    </>
  );
};
DeleteUserButton.propTypes = {
  setSuccessState: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  id: PropTypes.string,
};

export default DeleteUserButton;
