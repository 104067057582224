import { EachTag, TagContainer } from '../FormElements.styles';
import PropTypes from 'prop-types';
import React from 'react';

// For the tags of the types of courses
const TagSelect = ({ tags, setTags }) => {
  const onChange = async (e) => {
    const newTag = e.target.value;
    let found = await tags.find((ele) => {
      return ele === newTag;
    });
    if (!found) {
      setTags([...tags, newTag]);
    } else {
      setTags((prev) => {
        return prev.filter((item) => item !== newTag);
      });
    }
  };

  return (
    <TagContainer>
      {/* Container 1 */}
      <div className="seperate">
        <EachTag>
          <input
            type="checkbox"
            name="autoDealership"
            value="Automotive Dealership"
            onClick={onChange}
          />
          <label htmlFor="autoDealership">Automotive Dealerships</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="autoService"
            value="Automotive Service"
            onClick={onChange}
          />
          <label htmlFor="autoService">Automotive Service</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="collisionCenters"
            value="Collision Centers"
            onClick={onChange}
          />
          <label htmlFor="collisionCenters">Collision Centers</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="concrete"
            value="Concrete"
            onClick={onChange}
          />
          <label htmlFor="concrete">Concrete</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="demolition"
            value="Demolition"
            onClick={onChange}
          />
          <label htmlFor="demolition">Demolition</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="drywall"
            value="Drywall"
            onClick={onChange}
          />
          <label htmlFor="drywall">Drywall</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="electrical"
            value="Electrical"
            onClick={onChange}
          />
          <label htmlFor="electrical">Electrical</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="flooring"
            value="Flooring"
            onClick={onChange}
          />
          <label htmlFor="flooring">Flooring</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="forestry"
            value="Forestry"
            onClick={onChange}
          />
          <label htmlFor="forestry">Forestry</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="foundations"
            value="Foundations"
            onClick={onChange}
          />
          <label htmlFor="foundations">Foundations</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="framing"
            value="Framing"
            onClick={onChange}
          />
          <label htmlFor="framing">Framing</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="healthCare"
            value="Health Care"
            onClick={onChange}
          />
          <label htmlFor="healthCare">Health Care</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="heavyEquipment"
            value="Heavy Equipment"
            onClick={onChange}
          />
          <label htmlFor="heavyEquipment">Heavy Equipment</label>
        </EachTag>
      </div>
      {/* Container 2 */}
      <div className="seperate">
        <EachTag>
          <input
            type="checkbox"
            name="hospitality"
            value="Hospitality"
            onClick={onChange}
          />
          <label htmlFor="hospitality">Hospitality</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="HVACMechanical"
            value="HVAC Mechanical"
            onClick={onChange}
          />
          <label htmlFor="HVACMechanical">HVAC Mechanical</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="insulation"
            value="Insulation"
            onClick={onChange}
          />
          <label htmlFor="insulation">Insulation</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="janitorial"
            value="Janitorial"
            onClick={onChange}
          />
          <label htmlFor="janitorial">Janitorial</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="landscapingExcavation"
            value="Landscaping & Excavation"
            onClick={onChange}
          />
          <label htmlFor="landscapingExcavation">
            Landscaping & Excavation
          </label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="marine"
            value="Marine"
            onClick={onChange}
          />
          <label htmlFor="marine">Marine</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="painting"
            value="Painting"
            onClick={onChange}
          />
          <label htmlFor="painting">Painting</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="plumbing"
            value="Plumbing"
            onClick={onChange}
          />
          <label htmlFor="plumbing">Plumbing</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="Remediation"
            value="Remediation"
            onClick={onChange}
          />
          <label htmlFor="Remediation">Remediation</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="rental"
            value="Rental"
            onClick={onChange}
          />
          <label htmlFor="rental">Rental</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="roofing"
            value="Roofing"
            onClick={onChange}
          />
          <label htmlFor="roofing">Roofing</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="safety"
            value="Safety"
            onClick={onChange}
          />
          <label htmlFor="safety">Safety</label>
        </EachTag>
        <EachTag>
          <input
            type="checkbox"
            name="trucking"
            value="Trucking"
            onClick={onChange}
          />
          <label htmlFor="trucking">Trucking</label>
        </EachTag>
      </div>
    </TagContainer>
  );
};
TagSelect.propTypes = {
  tags: PropTypes.array,
  setTags: PropTypes.func.isRequired,
};

export default TagSelect;
