import React, { useState } from 'react';
import { httpGetSingleCourse } from '../Hooks/courses.hooks';
import { useParams } from 'react-router-dom';
import EachQuestion from './EachQuestion/EachQuestionComponent.component';
import {
  QuizContainer,
  ButtonContainer,
  CourseInfoContainer,
  ButtonContainerFull,
} from './QuizPageStyles.styles';
import QuizSubmit from './QuizSubmitComponent.component';
import { useQuery } from '@tanstack/react-query';
import BreadCrumbs from '../Components/Breadcrumbs/BreadcrumbsComponent.component';
import { AuthCheckUser } from '../Utils/AuthCheck/UserCheck.auth';
import Loader from '../Components/Loader/LoaderComponent.component';
import { Helmet } from 'react-helmet';
import {
  CourseHomeRight,
  CouseHomeLeft,
} from '../TakeCourse/CourseHome.styles';
import SidebarQuestions from './SidebarQuestions.component';
import InfoCard from '../Components/InfoCard/InfoCard.component';

// prettier-ignore
// Component for doing the quiz
const QuizComponent = () => {
  const { id } = useParams();
  const [answered, setAnswered] = useState(0);
  const [correct, setCorrect] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [userAnswers, setUserAnswers] = useState({});

  const { data, error, isLoading } = useQuery({
    queryKey: ['userId', id],
    queryFn: () => httpGetSingleCourse(id),
    enabled: !!id,
    retry: 5,
  });

  const quizLength = data?.quiz.length;

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <h3>An error has occured</h3>;
  }

  return (
    <AuthCheckUser>
      <Helmet>
        <title> {data?.courseName ?  `${data?.courseName} Quiz ` : 'Quiz '}| Yoda Safety Services</title>
        <meta name="Quiz" content="Take the courses quiz" />
      </Helmet>
      <QuizContainer>
        <CourseHomeRight dissapear={true}>
          <BreadCrumbs
            one={data?.courseName}
            oneLink={`/course/home/${data?.courseName}`}
            two="Quiz"
          />
          <p>
            Questions Answered: {answered} / {quizLength}
          </p>
          <SidebarQuestions questions={data?.quiz} answeredQuestions={answeredQuestions}/>
          <ButtonContainerFull>
            <QuizSubmit
              id={data?._id}
              correct={correct}
              length={quizLength}
              courseName={data?.courseName}
              course={data}
              answered={answered}
              userAnswers={userAnswers}
            />
          </ButtonContainerFull>
        </CourseHomeRight>
        <CouseHomeLeft>
          <CourseInfoContainer>
            {/* <section> */}
            <h2>{data?.courseName} Quiz</h2>
          </CourseInfoContainer>
            {/* </section> */}
            <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
              <InfoCard paragraph=' To complete the course select one of the four given answers for
                multiple choice or true or false. Once you have answered all the
                questions click on the Submit Quiz button. If you pass the quiz
                you will recieve a certificate of completion. If you do not pass
                the quiz you can retake the quiz until you achieve the passing
                score.' span=' You must have a score of 60% or higher to pass this course.'/>
            </div>

          {data.quiz?.map((q, i) => {
            return (
              <EachQuestion
                key={q.id}
                i={i}
                q={q}
                setCorrect={setCorrect}
                setAnswered={setAnswered}
                correct={correct}
                setAnsweredQuestions={setAnsweredQuestions}
                answeredQuestions={answeredQuestions}
                setUserAnswers={setUserAnswers}
              />
            );
          })}
          <ButtonContainer>
            <QuizSubmit
              id={data?._id}
              correct={correct}
              length={quizLength}
              courseName={data?.courseName}
              course={data}
              answered={answered}
              userAnswers={userAnswers}
            />
          </ButtonContainer>
        </CouseHomeLeft>
      </QuizContainer>
    </AuthCheckUser>
  );
};

export default QuizComponent;
