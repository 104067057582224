import React, { useState } from 'react';
import PrimaryButton from '../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { httpForgotPassword } from '../../Hooks/users.hooks';
import InputContainer from '../../Components/Form/Input';
import { Helmet } from 'react-helmet';
import { PasswordOuterContianer } from './ForgotAndReset.styles';

// prettier-ignore
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [err, setErr] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const forgotPass = async (e) => {
    e.preventDefault();
    setSuccess('');
    setErr('');
    if (email.length < 9 || !email.includes('@')) {
      setErr('Not a valid email');
      return;
    }
    try {
      let res = await httpForgotPassword(email);
      let data = await res.json();
      if (res.ok) {
        setSuccess('Password reset link has been sent to your email');
      } else {
        setErr(data.msg);
      }
    } catch (err) {
      setErr('An error has occured');
    }
  };
  return (
    <>
        <Helmet>
          <title>Forgot Password | Yoda Safety Services</title>
          <meta name="Forgot Password" content="Update your password" />
        </Helmet>
        <PasswordOuterContianer>
          <h2>Forgot Password</h2>
          <p>
            Enter your email address to recieve a link to reset your password
          </p>
          {err && <p style={{ color: 'red' }}>{err}</p>}
          {success && <p style={{ color: 'green' }}>{success}</p>}
          <form>
            <InputContainer
              placeholder="johndoe@yoda.com"
              label="Email"
              value={email}
              name="email"
              onChange={handleChange}
            />
            <PrimaryButton functionName={forgotPass} span="Reset Password" />
          </form>
          </PasswordOuterContianer>
    </>
  );
};

export default ForgotPassword;
