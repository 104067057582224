import { httpEditCompany } from '../../../Hooks/admin.hooks';
import { formatNum } from '../../../Helpers/format-phone-number';

export const editCompany = async (
  company,
  setSuccessState,
  setIsOpen,
  setLoading,
  user
) => {
  try {
    const { phoneMobile, phoneOffice } = company;
    const companyPhone = await formatNum(phoneOffice);
    const mobilephone = await formatNum(phoneMobile);
    const updatedCompany = {
      ...company,
      phoneMobile: mobilephone,
      phoneOffice: companyPhone,
    };
    let res = await httpEditCompany(company._id, updatedCompany, user);
    let data = await res.json();
    if (res.ok) {
      setSuccessState('Company has been edited');
    } else {
      setSuccessState(data.msg);
    }
  } catch (err) {
    setSuccessState('An error occurred while editing the company');
    console.error('Error editing company:', err);
  } finally {
    setIsOpen(true);
    setLoading(false);
  }
};
