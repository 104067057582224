import {
  TopRightContainer,
  CourseContainer,
  OuterContainer,
  LeftContainer,
  RightContainer,
} from './UserHomeStyles.styles';
import React, { useState, Suspense } from 'react';
import Loader from '../../Components/Loader/LoaderComponent.component';
import UserCourses from './UserCoursesComponent.component';
import { AuthCheckUser } from '../../Utils/AuthCheck/UserCheck.auth';
import Auth from '../../Helpers/auth';
import TabButton from '../../Components/Buttons/TabButton/TabButton.component';
import { Helmet } from 'react-helmet';
import UserMenu from './UserMenuComponent.component';

// The base page for the users home - shows
const UserHome = () => {
  const { data } = Auth.getProfile();

  const [showAllCourses, setAllCourses] = useState(true);
  const [showCompleteCourses, setShowCompleteCourses] = useState(false);
  const [showIncompleteCourses, setShowIncompleteCourses] = useState(false);
  const [chosen, setChosen] = useState('AllCourses');

  return (
    <AuthCheckUser>
      <Helmet>
        <title>User Home | Yoda Safety Services</title>
        <meta name="User Home" content="Users Homepage" />
      </Helmet>
      <OuterContainer>
        <>
          <LeftContainer>
            <UserMenu data={data} />
          </LeftContainer>
          <RightContainer>
            <TopRightContainer>
              <TabButton
                span="All Courses"
                functionName={(e) => {
                  e.preventDefault();
                  setAllCourses(true);
                  setShowIncompleteCourses(false);
                  setShowCompleteCourses(false);
                  setChosen('AllCourses');
                }}
                active={showAllCourses ? true : false}
              />
              <TabButton
                active={showIncompleteCourses ? true : false}
                functionName={(e) => {
                  e.preventDefault();
                  setAllCourses(false);
                  setShowIncompleteCourses(true);
                  setShowCompleteCourses(false);
                  setChosen('IncompleteCourses');
                }}
                span="Incomplete Courses"
              />
              <TabButton
                active={showCompleteCourses ? true : false}
                functionName={(e) => {
                  e.preventDefault();
                  setAllCourses(false);
                  setShowIncompleteCourses(false);
                  setShowCompleteCourses(true);
                  setChosen('CompleteCourses');
                }}
                span="Complete Courses"
              />
            </TopRightContainer>
            {/* Displays the users courses that are chosen */}
            <CourseContainer>
              <Suspense fallback={<Loader />}>
                <UserCourses user={data} chosen={chosen} />
              </Suspense>
            </CourseContainer>
          </RightContainer>
        </>
      </OuterContainer>
    </AuthCheckUser>
  );
};

export default UserHome;
