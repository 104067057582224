import { useNavigate, Link } from 'react-router-dom';
import {
  EachCourse,
  BottomCourseContainer,
  CourseStatusContainer,
} from './CourseEntity.styles';
import PrimaryButton from '../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import React from 'react';
import PropTypes from 'prop-types';
import SecondaryButton from '../../Components/Buttons/SecondaryButton/SecondaryButton.component';
// Entity on user home page for showing that the course is incomplete
const IncompleteCourse = ({ courses }) => {
  const navigate = useNavigate();

  return (
    <div>
      {!courses?.length && (
        <>
          <CourseStatusContainer>
            <h3>You have no incomplete courses</h3>
            <SecondaryButton
              functionName={() => navigate('/online')}
              span="Browse Courses"
            />
          </CourseStatusContainer>
        </>
      )}
      {courses?.map((c) => {
        if (!c?.complete) {
          return (
            <EachCourse key={c?.courseId} className="incomplete boxShadow">
              <div className="top">
                <Link className="title" to={`/course/home/${c?.courseId}`}>
                  {c?.courseName}
                </Link>
              </div>
              <BottomCourseContainer>
                <span className="italicSpan">This course is incomplete</span>

                <PrimaryButton
                  functionName={() => navigate(`/course/home/${c.courseId}`)}
                  span="Take Course"
                />
              </BottomCourseContainer>
            </EachCourse>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};
IncompleteCourse.propTypes = {
  courses: PropTypes.array,
};

export default IncompleteCourse;
