import { SubmitContainer } from '../../../CreateRoutes/CreateForm.styles';
import DeleteCompanyButton from '../CompanyEditDelete.component';
import DeactivateCompanyButton from '../CompanyEditDeactivate.component';
import SubmitEditCompany from '../CompanyEditSubmit.component';
import TertiaryButton from '../../../../Components/Buttons/TertiaryButton/TertiaryButton.component';
import PropTypes from 'prop-types';
import React from 'react';
import ActivateCompanyButton from '../CompanyEditActive.component';

const EditFormButtons = ({
  company = {},
  gotoManagers,
  setIsOpen,
  setSuccessState,
  logo = '',
}) => {
  return (
    <>
      <SubmitContainer>
        <DeleteCompanyButton
          id={company?._id}
          setIsOpen={setIsOpen}
          setSuccessState={setSuccessState}
        />
        {company?.active ? (
          <DeactivateCompanyButton
            id={company?._id}
            setIsOpen={setIsOpen}
            setSuccessState={setSuccessState}
          />
        ) : (
          <ActivateCompanyButton
            id={company?._id}
            setIsOpen={setIsOpen}
            setSuccessState={setSuccessState}
          />
        )}
        <SubmitEditCompany
          company={company}
          setIsOpen={setIsOpen}
          setSuccessState={setSuccessState}
          logo={logo}
        />
      </SubmitContainer>

      <div style={{ marginBlock: '2rem' }}>
        <TertiaryButton
          functionName={gotoManagers}
          span="Edit Managers and Employees"
        />
      </div>
    </>
  );
};

EditFormButtons.propTypes = {
  company: PropTypes.object,
  gotoManagers: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setSuccessState: PropTypes.func.isRequired,
  logo: PropTypes.string,
};

export default EditFormButtons;
