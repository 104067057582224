import PropTypes from 'prop-types';
import React from 'react';
import { EachTag } from '../../Components/Form/FormElements.styles';

const RadioTag = ({ tagChange, setSelectedTag }) => {
  const onChange = async (e) => {
    await setSelectedTag(e.target.value);
    await tagChange(e.target.value);
  };

  return (
    <form>
      <EachTag>
        <input
          defaultChecked={true}
          type="radio"
          id="all"
          name="tag"
          value="all"
          onClick={onChange}
        />
        <label htmlFor="all">All Courses</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          id="autoDealership"
          name="tag"
          value="Automotive Dealership"
          onClick={onChange}
        />
        <label htmlFor="autoDealership">Automotive Dealerships</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          id="autoService"
          name="tag"
          value="Automotive Service"
          onClick={onChange}
        />
        <label htmlFor="autoService">Automotive Service</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="collisionCenters"
          value="Collision Centers"
          onClick={onChange}
        />
        <label htmlFor="collisionCenters">Collision Centers</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="concrete"
          value="Concrete"
          onClick={onChange}
        />
        <label htmlFor="concrete">Concrete</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="demolition"
          value="Demolition"
          onClick={onChange}
        />
        <label htmlFor="demolition">Demolition</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="drywall"
          value="Drywall"
          onClick={onChange}
        />
        <label htmlFor="drywall">Drywall</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="electrical"
          value="Electrical"
          onClick={onChange}
        />
        <label htmlFor="electrical">Electrical</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="flooring"
          value="Flooring"
          onClick={onChange}
        />
        <label htmlFor="flooring">Flooring</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="forestry"
          value="Forestry"
          onClick={onChange}
        />
        <label htmlFor="forestry">Forestry</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="foundations"
          value="Foundations"
          onClick={onChange}
        />
        <label htmlFor="foundations">Foundations</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="framing"
          value="Framing"
          onClick={onChange}
        />
        <label htmlFor="framing">Framing</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="healthCare"
          value="Health Care"
          onClick={onChange}
        />
        <label htmlFor="healthCare">Health Care</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="heavyEquipment"
          value="Heavy Equipment"
          onClick={onChange}
        />
        <label htmlFor="heavyEquipment">Heavy Equipment</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="hospitality"
          value="Hospitality"
          onClick={onChange}
        />
        <label htmlFor="hospitality">Hospitality</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="HVACMechanical"
          value="HVAC Mechanical"
          onClick={onChange}
        />
        <label htmlFor="HVACMechanical">HVAC Mechanical</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="insulation"
          value="Insulation"
          onClick={onChange}
        />
        <label htmlFor="insulation">Insulation</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="janitorial"
          value="Janitorial"
          onClick={onChange}
        />
        <label htmlFor="janitorial">Janitorial</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="landscapingExcavation"
          value="Landscaping & Excavatio"
          onClick={onChange}
        />
        <label htmlFor="landscapingExcavation">Landscaping & Excavation</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="marine"
          value="Marine"
          onClick={onChange}
        />
        <label htmlFor="marine">Marine</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="painting"
          value="Painting"
          onClick={onChange}
        />
        <label htmlFor="painting">Painting</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="plumbing"
          value="Plumbing"
          onClick={onChange}
        />
        <label htmlFor="plumbing">Plumbing</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="Remediation"
          value="Remediation"
          onClick={onChange}
        />
        <label htmlFor="Remediation">Remediation</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="rental"
          value="Rental"
          onClick={onChange}
        />
        <label htmlFor="rental">Rental</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="roofing"
          value="Roofing"
          onClick={onChange}
        />
        <label htmlFor="roofing">Roofing</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="safety"
          value="Safety"
          onClick={onChange}
        />
        <label htmlFor="safety">Safety</label>
      </EachTag>
      <EachTag>
        <input
          type="radio"
          name="tag"
          id="trucking"
          value="Trucking"
          onClick={onChange}
        />
        <label htmlFor="trucking">Trucking</label>
      </EachTag>
    </form>
  );
};
RadioTag.propTypes = {
  tagChange: PropTypes.func.isRequired,
  setSelectedTag: PropTypes.func.isRequired,
};

export default RadioTag;
