import { NewBurger } from './BurgerStyles.styles';
import React from 'react';
import PropTypes from 'prop-types';
import MenuIcon from '../../../Visuals/Icons/MenuIcon.icon';

const Burger = ({ open, setOpen }) => {
  return (
    <NewBurger open={open} onClick={() => setOpen(!open)}>
      <MenuIcon />
    </NewBurger>
  );
};
Burger.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default Burger;
