import CompletedCourse from './CompleteCourse.component';
import IncompleteCourse from './IncompleteCourse.component';
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import SecondaryButton from '../../Components/Buttons/SecondaryButton/SecondaryButton.component';
import { CourseStatusContainer } from './CourseEntity.styles';

// Shows complete and incomplete courses on the users home page
const AllCourses = ({ courses, data }) => {
  const navigate = useNavigate();

  return (
    <div>
      {!courses?.length && (
        <>
          <CourseStatusContainer>
            <h3>You currently have no courses purchased</h3>
            <SecondaryButton
              functionName={() => navigate('/online')}
              span="Browse Courses"
            />
          </CourseStatusContainer>
        </>
      )}
      <IncompleteCourse courses={courses} />
      <CompletedCourse courses={courses} data={data} />
    </div>
  );
};
AllCourses.propTypes = {
  courses: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default AllCourses;
