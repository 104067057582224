import { MainContainer, MainSection } from '../MainRoute.styles';
import React from 'react';
import { Helmet } from 'react-helmet';

const WCBCertified = () => {
  return (
    <MainContainer>
      <Helmet>
        <title>WCB Certified | Yoda Safety Services</title>
        <meta
          name="WCB Certified"
          content="Yoda Safety Services is WCB Certified"
        />
      </Helmet>
      <h1>Approved By NS WCB Safety Certified</h1>
      <MainSection>
        <p>
          {' '}
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Officia ea
          ipsum nulla rem, dolore inventore. Saepe nesciunt adipisci
          repudiandae. Quisquam temporibus recusandae ipsum tenetur sapiente
          eveniet aut expedita praesentium dolor.
        </p>
      </MainSection>
    </MainContainer>
  );
};

export default WCBCertified;
