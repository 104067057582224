//import Auth from "../../Helpers/auth";
import { ButtonContainer } from '../Completed/QuizComplete.styles';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import SecondaryButton from '../../Components/Buttons/SecondaryButton/SecondaryButton.component';
import React from 'react';
import PropTypes from 'prop-types';

// Page from when the user has no completed the course
const UserIncompleteComponent = ({ id }) => {
  //const { data } = Auth.getProfile();
  const navigate = useNavigate();
  return (
    <>
      <h1>Sorry, You have not completed this course</h1>
      <ButtonContainer>
        <SecondaryButton
          functionName={(e) => {
            e.preventDefault();
            navigate('/user');
          }}
          span="Go Home"
        />
        <PrimaryButton
          functionName={(e) => {
            e.preventDefault();
            navigate(`/course/quiz/${id}`);
          }}
          span="Retake Quiz"
        />
      </ButtonContainer>
    </>
  );
};
UserIncompleteComponent.propTypes = {
  id: PropTypes.string.isRequired,
};

export default UserIncompleteComponent;
