import { useNavigate } from 'react-router-dom';
import {
  CourseCardComponent,
  CourseImage,
  CourseInfo,
} from './TrainingStyles.styles';
import TertiaryButton from '../../Components/Buttons/TertiaryButton/TertiaryButton.component';
import React from 'react';
import PropTypes from 'prop-types';

const CourseCardSingle = ({ c }) => {
  const navigate = useNavigate();
  const learnMore = (e, id) => {
    e.preventDefault();
    navigate(`/course/${id}`);
  };

  return (
    <CourseCardComponent key={c?._id}>
      <CourseImage>
        <img src={c?.image} alt={c?.courseName} />
      </CourseImage>
      <CourseInfo>
        <div className="topContainer">
          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
            }}
          > */}
          <h4>{c?.courseName}</h4>
          {/* <p style={{ paddingRight: '1rem' }}>${c?.price}</p> */}
          {/* </div> */}
          <p>
            {c?.description.length <= 100
              ? c?.description
              : c?.description.slice(0, 100) + ' ...'}
          </p>
        </div>
        <div className="buttonContainer">
          <TertiaryButton
            functionName={(e) => learnMore(e, c?._id)}
            span="Learn more"
          />
        </div>
      </CourseInfo>
    </CourseCardComponent>
  );
};
CourseCardSingle.propTypes = {
  c: PropTypes.shape({
    _id: PropTypes.string,
    image: PropTypes.string,
    courseName: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
  }).isRequired,
};

export default CourseCardSingle;
