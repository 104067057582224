import React from 'react';
import TertiaryButton from '../Components/Buttons/TertiaryButton/TertiaryButton.component';
import { OptionsContainer, SwitchPage } from './AuthStyles.styles';
import PropTypes from 'prop-types';

const SignupOptions = ({ setPage }) => {
  const switchPage = (e) => {
    e.preventDefault();
    setPage(true);
  };

  return (
    <>
      <OptionsContainer>
        <SwitchPage>
          <p style={{ fontWeight: '800' }}>Have An Account?</p>
          <TertiaryButton functionName={switchPage} span="Login" />
        </SwitchPage>
      </OptionsContainer>
    </>
  );
};
SignupOptions.propTypes = {
  setPage: PropTypes.func.isRequired,
};

export default SignupOptions;
