import styled from 'styled-components';

export const AdminContainer = styled.main`
  width: 100%;
  display: flex;
`;

export const LeftAdmin = styled.aside`
  width: 30%;
  padding-top: ${({ theme }) => theme.spacing.xl};
  padding-inline: ${({ theme }) => theme.spacing.xl};
  /* border: 1px solid orange; */
  background-color: ${({ theme }) => theme.colors.grey6};
`;
export const TopLeftAdmin = styled.div`
  h2 {
    margin-bottom: ${({ theme }) => theme.spacing.m};
    color: ${({ theme }) => theme.colors.grey};
    text-transform: capitalize;
  }
  h2::after {
    width: 0%;
  }
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  border-bottom: 3px solid ${({ theme }) => theme.colors.yellow5};
`;

export const BottomLeftAdmin = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.m};
  a {
    color: ${({ theme }) => theme.colors.grey1};
  }
`;

export const RightAdmin = styled.section`
  width: 70%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.grey};
  /* border: 1px solid green; */
`;

export const AdminTabs = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.m};
  /* padding-block: ${({ theme }) => theme.spacing.m}; */
  /* border: 1px solid pink; */
  padding-left: ${({ theme }) => theme.spacing.l};
  padding-top: ${({ theme }) => theme.spacing.l};
  background-color: ${({ theme }) => theme.colors.grey5};
`;

export const AdminTable = styled.div`
  /* border: 1px solid blue; */
  padding: ${({ theme }) => theme.spacing.l};
`;

export const InsideSearch = styled.div`
  /* border: 1px solid green; */
  input {
    width: 40%;
  }
`;
