import React from 'react';
import PropTypes from 'prop-types';

const CompanyLogo = ({ company, setLogo }) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '1.5rem',
        marginBlock: '1rem',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid var(--grey)',
        }}
      >
        <label>Company Logo</label>
        {company?.logo ? (
          <img src={company?.logo} alt="Company Logo" />
        ) : (
          <span>No logo</span>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid var(--grey)',
          paddingBottom: '1rem',
          marginBottom: '1rem',
        }}
      >
        <label style={{ paddingRight: '1rem' }}>Change Logo</label>
        <input
          type="file"
          accept="image/png, image/jpg"
          onChange={(e) => setLogo(e.target.files[0])}
        />
      </div>
    </div>
  );
};
CompanyLogo.propTypes = {
  company: PropTypes.shape({
    logo: PropTypes.string,
  }),
  setLogo: PropTypes.func.isRequired,
};

export default CompanyLogo;
