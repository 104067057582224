import { jwtDecode } from 'jwt-decode';

class AuthService {
  getProfile() {
    return jwtDecode(this.getToken());
  }

  loggedIn() {
    const token = this.getToken();
    return token && !this.isTokenExpired(token) ? true : false;
  }

  isTokenExpired(token) {
    const decoded = jwtDecode(token);
    if (decoded.exp < Date.now() / 1000) {
      localStorage.removeItem('yoda_token');
      window.location.assign('/login');
      return true;
    }
    return false;
  }

  getToken() {
    return localStorage.getItem('yoda_token');
  }

  login(idToken) {
    localStorage.setItem('yoda_token', idToken);
    window.location.assign('/user');
  }

  logout() {
    localStorage.removeItem('yoda_token');
    //window.location.assign('/');
  }
}
const authToken = new AuthService();
export default authToken;
