import React from 'react';
import PropTypes from 'prop-types';
import { TabComponent } from './TabButton.styles';

const TabButton = ({ functionName, span, active }) => {
  return (
    <TabComponent onClick={functionName} active={active}>
      <span>{span}</span>
    </TabComponent>
  );
};
TabButton.propTypes = {
  functionName: PropTypes.func.isRequired,
  span: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default TabButton;
