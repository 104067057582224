import { httpGetSingleUser } from '../../../Hooks/admin.hooks';

export const fetchUserAdmin = async (id, setUser, setSuccess) => {
  try {
    const foundUser = await httpGetSingleUser(id);
    if (foundUser) {
      setUser(foundUser);
    } else {
      setSuccess('No user found');
    }
  } catch (error) {
    setSuccess('An error occurred while fetching the user');
    console.error('Error fetching user:', error);
  }
};
