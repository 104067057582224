import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SearchInput from '../SearchInputComponent.component';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton/PrimaryButton.component';
import { InsideSearch } from '../AdminStyles.styles';

const SearchUsers = ({ users, setFiltered, setErr }) => {
  const [search, setSearch] = useState('');
  const setSearchUsers = async (e) => {
    e.preventDefault();
    setErr('');
    const searchTerm = search.toLowerCase();
    const searchResults = users.filter(
      (user) =>
        user.firstName.toLowerCase().includes(searchTerm) ||
        user.lastName.toLowerCase().includes(searchTerm)
    );
    const uniqueResults = Array.from(
      new Set(searchResults.map((user) => user._id))
    ).map((id) => searchResults.find((user) => user._id === id));
    if (!uniqueResults.length) {
      setErr('No users found');
    }
    setFiltered(uniqueResults);
  };
  return (
    <InsideSearch>
      <SearchInput value={search} name="users" setValue={setSearch} />
      <PrimaryButton functionName={setSearchUsers} span="search" />
    </InsideSearch>
  );
};
SearchUsers.propTypes = {
  users: PropTypes.array.isRequired,
  setFiltered: PropTypes.func.isRequired,
  setErr: PropTypes.func.isRequired,
};

export default SearchUsers;
